import React, { useCallback, useState, useEffect } from 'react';
import './Header.css';
import mobiscroll from '@mobiscroll/react4';
import { Popup } from '@mobiscroll/react';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import DayJS from 'react-dayjs';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSitemap } from "@fortawesome/free-solid-svg-icons";

function Header(props) {
    function Empty(data) {
        return Object.keys(data).length === 0;
    }

    const [userPop, setUserPop] = useState(false);
    const [resetPop, setResetPop] = useState(false);
    const [newLocationPop, setNewLocationPop] = useState(false);
    const [firstName, setFirstName] = useState(props.user.firstName);
    const [lastName, setLastName] = useState(props.user.lastName);
    const [mobile, setMobile] = useState(props.user.mobile);
    const [email, setEmail] = useState(props.user.email);
    const [avatar, setAvatar] = useState(props.avatar);
    const [account, setAccount] = useState(props.account.id);
    const [location, setLocation] = useState(props.user.location.id);
    const [menuMenu, setMenuMenu] = useState('Hi ' + props.user.firstName + ' ▾');
    const [passReset, setPassReset] = useState('');
    const [passHash, setPassHash] = useState('');
    const [mobiAccounts, setMobiAccounts] = useState(props.mobiAccounts);
    const [mobiLocations, setMobiLocations] = useState(props.mobiLocations);
    const [mobiLocationsInstance, setMobiLocationsInstance] = useState({});
    const [mobiStates, setMobiStates] = useState(props.mobiStates);
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');

    const openAccount = useCallback(() => {
        setUserPop(true);
    }, []);

    const closeAccount = useCallback(() => {
        setUserPop(false);
    }, []);

    const closeNewLocation = useCallback(() => {
        setNewLocationPop(false);
    }, []);

    const openReset = useCallback(() => {
        setUserPop(false);
        setResetPop(true);
    }, []);

    const closeReset = useCallback(() => {
        setPassReset('');
        setPassHash('');
         setResetPop(false);
    }, []);

    const Menu = useCallback((path) => {
        if (path === 'Logout') {
            props.onLogout();
        } else if (path === 'My Account') {
            openAccount();
        } else if (path === 'Workflows') {
            props.onSetNavigationMenu('workflows');
        } else if (path === 'Location') {
            props.onSetNavigationMenu('locations');
        } else if (path === 'Clusters') {
            props.onSetNavigationMenu('clusters');
        } else if (path === 'Customers') {
            props.onSetNavigationMenu('customers');
        } else if (path === 'Dashboard') {
            props.onSetNavigationMenu('dashboard');
        } else if (path === 'Reporting') {
            props.onSetNavigationMenu('reporting');
        } else if (path === 'Support') {
            props.onSetNavigationMenu('support');
        } else if (path === 'Users') {
            props.onSetNavigationMenu('users');
        }
    }, []);

    function User() {
        if (!firstName || !mobile || !lastName) {
            mobiscroll.toast({ message: 'Please complete all the fields', color: 'warning'});
        } else {
            let message = {
                task: 'saveUser',
                data: { email: email, firstName: firstName, lastName: lastName, mobile: mobile }
            };
            props.onSubmit(message);
        }
    }

    function Account(data) {
        let item = props.user;
        item.location = {};
        props.onSetUser(item);
        setLocation('');

        let message = {
            task: 'account',
            data: { account: data }
        };
        props.onSubmit(message);
    }

    function Location(data) {
        let item = props.user;
        item.location = {
            id: data,
            account: account
        };
        props.onSetUser(item);

        let message = {
            task: 'location',
            data: { account: account, location: data }
        };
        props.onSubmit(message);
    }

    function NewLocation() {
        setName('');
        setAddress('');
        setCity('');
        setState('');
        setZip('');
        setNewLocationPop(true);
        mobiLocationsInstance.cancel();
    }

    function SaveNewLocation() {
        if (!name ) {
            mobiscroll.toast({ message: 'Please enter a name for the location', color: 'warning'});
        } else {
            let message = {
                task: 'newLocation',
                data: {
                    account: account,
                    name: name,
                    address: address,
                    city: city,
                    state: state,
                    zip: zip
                }
            };
            props.onSubmit(message);
        }
    }

    function Password() {
        if (!passReset || !passHash) {
            mobiscroll.toast({ message: 'Please type a new password', color: 'warning'});
        } else if (passReset !== passHash) {
            mobiscroll.toast({ message: 'Please ensure both passwords match', color: 'warning'});
        } else {
            let message = {
                task: 'resetPassword',
                data: { email: props.user.email, pass: passReset }
            };
            props.onSubmit(message);
            closeReset();
        }
    }

    useEffect(() => {
    }, []);

    useEffect(() => {
        setMobiLocations(props.mobiLocations);
    }, [props.mobiLocations]);

    useEffect(() => {
        if (!Empty(props.response) && props.response.task === 'account') {
            if (props.response.data.account) {
                props.onSetAccount(props.response.data.account);
                setAccount(props.response.data.account.id);
            }
            if (props.response.data.mobiLocations) {
                props.onSetMobiLocations(props.response.data.mobiLocations);
                setMobiLocations(props.response.data.mobiLocations);
            }
        }
        if (!Empty(props.response) && props.response.task === 'location') {
            if (props.response.data.location) {
                props.onSetLocation(props.response.data.location);
            }
        }
        if (!Empty(props.response) && props.response.task === 'newLocation') {
            closeNewLocation();
            if (props.response.data.mobiLocations) {
                props.onSetMobiLocations(props.response.data.mobiLocations);
                setMobiLocations(props.response.data.mobiLocations);
            }
        }
    }, [props.response])

    return (
        <div className="mbsc-grid header-toolbar">
            <div className="mbsc-row mbsc-justify-content-between">
                <div className="mbsc-row mbsc-justify-content-start">
                    {props.width > 720 &&
                        <>
                            <div className="logo-non-form"></div>
                            <div className="mbsc-padding">|</div>
                        </>
                    }
                    { (props.user.priv.superAdmin || props.user.priv.admin || props.user.priv.owner ) ?
                        <mobiscroll.Select placeholder="Select location" id="header-select" rows="8" display={'center'} filter={true} select="single" data={mobiLocations} value={location} buttons={[{text:'New location',handler: function(event){ NewLocation() }}]} onSet={function (event, inst){Location(inst.getVal())}} onInit={function (event, inst) { setMobiLocationsInstance(inst) }}>
                            <mobiscroll.Input></mobiscroll.Input>
                        </mobiscroll.Select>
                        :
                        <mobiscroll.Select placeholder="Select location" id="header-select" rows="8" display={'center'} filter={true} select="single" data={mobiLocations} value={location} buttons={[]} onSet={function (event, inst) {Location(inst.getVal())}} >
                            <mobiscroll.Input></mobiscroll.Input>
                        </mobiscroll.Select>
                    }
                    {props.user.priv.superAdmin === true && mobiAccounts && props.width > 720 &&
                        <>
                            <div className="mbsc-padding">|</div>
                            <mobiscroll.Select id="header-select" rows="8" display={'center'} filter={true} select="single" data={mobiAccounts} value={account} buttons={[]} onSet={function (event, inst) {Account(inst.getVal())}} >
                                <mobiscroll.Input></mobiscroll.Input>
                            </mobiscroll.Select>
                        </>
                    }
                </div>
                <div className="mbsc-row mbsc-justify-content-end header-toolbar-right">
                    {props.navigationMenu !== 'dashboard' &&
                        <>
                            <div className="mbsc-padding breadcrumb mbsc-bold" onClick={(ev) => { props.onSetNavigationMenu('dashboard') }}>
                                Dashboard
                            </div>
                            <div className="mbsc-padding">|</div>
                        </>
                    }
                    <div className="mbsc-row mbsc-padding theme-white header-margin-icon">
                        <mobiscroll.Avatar src={avatar} />
                    </div>
                    <mobiscroll.HamburgerNav onItemTap={function (event, inst) {Menu(event.target.innerText); inst.deselect(event.target)}} menuText={menuMenu} menuIcon="" className="menu-non-form" theme="ios" themeVariant="light" type="hamburger">
                        {props.user.priv.superAdmin === true &&
                            <>
                                <mobiscroll.NavItem disabled={props.navigationMenu === 'workflows'} icon="material-share">Workflows</mobiscroll.NavItem>
                                <mobiscroll.NavItem icon="office">Customers</mobiscroll.NavItem>
                                <mobiscroll.NavItem icon="map">Clusters</mobiscroll.NavItem>
                            </>
                        }
                        {( props.user.priv.superAdmin === true || props.user.priv.admin === true || props.user.priv.owner === true ) &&
                            <>
                                <mobiscroll.NavItem disabled={props.navigationMenu === 'locations'} icon="location">Location</mobiscroll.NavItem>
                            </>
                        }
                        <mobiscroll.NavItem icon="support">Support</mobiscroll.NavItem>
                        <mobiscroll.NavItem icon="line-user">My Account</mobiscroll.NavItem>
                        <mobiscroll.NavItem icon="ion-close-circled">Logout</mobiscroll.NavItem>
                    </mobiscroll.HamburgerNav>
                </div>
            </div>
            <Popup theme="ios" themeVariant="light" display="center" fullScreen={true} headerText="My Account" buttons={[{text:'Save',handler: function(event){ User() }}, 'cancel']} isOpen={userPop} onClose={closeAccount}>
                <mobiscroll.Form>
                    <mobiscroll.FormGroup inset>
                        <mobiscroll.Input disabled type="text" name="email" placeholder="Email"  value={email}>Email</mobiscroll.Input>
                        <mobiscroll.Input type="text" name="firstName" placeholder="First name" value={firstName} onChange={(ev) => { setFirstName(ev.target.value) }}>First Name</mobiscroll.Input>
                        <mobiscroll.Input type="text" name="lastName" placeholder="Last name" value={lastName} onChange={(ev) => { setLastName(ev.target.value) }}>Last Name</mobiscroll.Input>
                        <mobiscroll.Input type="tel" name="mobile" placeholder="Mobile number" value={mobile} onChange={(ev) => { setMobile(ev.target.value) }}>Mobile</mobiscroll.Input>
                    </mobiscroll.FormGroup>
                    <mobiscroll.FormGroup inset>
                        <mobiscroll.Button onClick={openReset} block={true}>Reset password</mobiscroll.Button>
                    </mobiscroll.FormGroup>
                </mobiscroll.Form>
            </Popup>
            <Popup theme="ios" themeVariant="light" display="center" fullScreen={true} headerText="New Location" buttons={[{text:'Save',handler: function(event){ SaveNewLocation() }}, 'cancel']} isOpen={newLocationPop} onClose={closeNewLocation}>
                <mobiscroll.Form>
                    <mobiscroll.FormGroup inset>
                        <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" name="Name" placeholder="name" value={name} onChange={(ev) => { setName(ev.target.value) }}>
                            Location name
                        </mobiscroll.Input>
                        <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" name="Address" placeholder="address" value={address} onChange={(ev) => { setAddress(ev.target.value) }}>
                            Address
                        </mobiscroll.Input>
                        <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" name="City" placeholder="city" value={city} onChange={(ev) => { setCity(ev.target.value) }}>
                            City
                        </mobiscroll.Input>
                        <mobiscroll.Select placeholder="state" select="single" touchUi={false} data={mobiStates} value={state} buttons={[]} onSet={function (event, inst) {setState(inst.getVal())}} >
                            <mobiscroll.Input inputStyle="box" labelStyle="inline" >State</mobiscroll.Input>
                        </mobiscroll.Select>
                        <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" name="Zip" placeholder="code" value={zip} onChange={(ev) => { setZip(ev.target.value) }}>
                            Zip code
                        </mobiscroll.Input>
                    </mobiscroll.FormGroup>
                </mobiscroll.Form>
            </Popup>
            <Popup theme="ios" themeVariant="light" display="center" fullScreen={true} headerText="Password Reset" buttons={[]} isOpen={resetPop} onClose={closeReset}>
                <mobiscroll.Form>
                    <mobiscroll.FormGroup inset>
                        <mobiscroll.Input type="password" name="passReset" passwordToggle={true} icon="none" iconAlign="right" placeholder="New password" value={passReset} onChange={(ev) => { setPassReset(ev.target.value) }}>New password</mobiscroll.Input>
                        <mobiscroll.Input type="password" name="passHash" passwordToggle={true} icon="none" iconAlign="right" placeholder="Confirm password" value={passHash} onChange={(ev) => { setPassHash(ev.target.value) }}>Confirm password</mobiscroll.Input>
                    </mobiscroll.FormGroup>
                    <mobiscroll.FormGroup inset>
                        <mobiscroll.Button onClick={Password} block={true}>Reset</mobiscroll.Button>
                        <mobiscroll.Button onClick={closeReset} block={true}>Cancel</mobiscroll.Button>
                    </mobiscroll.FormGroup>
                </mobiscroll.Form>
            </Popup>
        </div>
    );
}

export default Header;
