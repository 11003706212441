import React, {useCallback, useEffect, useRef, useState} from 'react';
import './Support.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import MicRecorder from 'mic-recorder-to-mp3';
import DayJS from 'react-dayjs';
import {Popup} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';

function Support(props) {
    function Empty(data) {
        return Object.keys(data).length === 0;
    }

  return (
      <div>

      </div>
  );
}

export default Support;