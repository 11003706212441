import React, {useCallback, useEffect, useRef, useState} from 'react';
import './NodeOutput.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import DayJS from 'react-dayjs';
import {Popup, Select, Textarea} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Handle, useEdges} from 'react-flow-renderer';

function NodeOutput(props) {
    const [one, setOne] = useState({});
    const [two, setTwo] = useState({});
    const [three, setThree] = useState({});
    const [four, setFour] = useState({});
    const [five, setFive] = useState({});
    const [six, setSix] = useState({});
    const [seven, setSeven] = useState({});
    const [eight, setEight] = useState({});

    function OpenNode() {
        props.data.onNode(props.id);
    }

    function OpenEdge(data) {
        props.data.onEdge({id: props.id, edge: data});
    }

    useEffect(() => {
            if (props.data.type === 'one') {
                setOne({ background: '#43BE5F' });
            }
            if (props.data.type === 'two' || props.data.type === 'multiple') {
                setOne({ left: 'calc(25%)', right: 'calc(75%)', background: '#43BE5F' });
                setTwo({ right: 'calc(25%)', left: 'calc(75%)', background: '#f5504e' });
            }
            if (props.data.type === 'three') {
                setOne({ left: 'calc(17%)', right: 'calc(83%)', background: '#43BE5F' });
                setTwo({ background: '#f5504e' });
                setThree({ right: 'calc(17%)', left: 'calc(83%)', background: '#3f97f6' });
            }
            if (props.data.type === 'four') {
                setOne({ left: 'calc(12%)', right: 'calc(88%)', background: '#43BE5F' });
                setTwo({ left: 'calc(37%)', right: 'calc(63%)', background: '#f5504e' });
                setThree({ right: 'calc(37%)', left: 'calc(63%)', background: '#3f97f6' });
                setFour({ right: 'calc(12%)', left: 'calc(88%)', background: '#f8b042' });
            }
            if (props.data.type === 'five') {
                setOne({ left: 'calc(10%)', right: 'calc(90%)', background: '#43BE5F' });
                setTwo({ left: 'calc(30%)', right: 'calc(70%)', background: '#f5504e' });
                setThree({ background: '#3f97f6' });
                setFour({ right: 'calc(30%)', left: 'calc(70%)', background: '#f8b042' });
                setFive({ right: 'calc(10%)', left: 'calc(90%)', background: '#5BB7C5' });
            }
            if (props.data.type === 'six') {
                setOne({ left: 'calc(9%)', right: 'calc(91%)', background: '#43BE5F' });
                setTwo({ left: 'calc(25%)', right: 'calc(75%)', background: '#f5504e' });
                setThree({ left: 'calc(42%)', right: 'calc(58%)', background: '#3f97f6' });
                setFour({ right: 'calc(41%)', left: 'calc(59%)', background: '#f8b042' });
                setFive({ right: 'calc(24%)', left: 'calc(76%)', background: '#5BB7C5' });
                setSix({ right: 'calc(8%)', left: 'calc(92%)', background: '#90979E' });
            }
            if (props.data.type === 'seven') {
                setOne({ left: 'calc(7%)', right: 'calc(93%)', background: '#43BE5F' });
                setTwo({ left: 'calc(21%)', right: 'calc(79%)', background: '#f5504e' });
                setThree({ left: 'calc(36%)', right: 'calc(64%)', background: '#3f97f6' });
                setFour({ background: '#f8b042' });
                setFive({ right: 'calc(36%)', left: 'calc(64%)', background: '#5BB7C5' });
                setSix({ right: 'calc(21%)', left: 'calc(79%)', background: '#90979E' });
                setSeven({ right: 'calc(7%)', left: 'calc(93%)', background: 'white' });
            }
            if (props.data.type === 'eight') {
                setOne({ left: 'calc(6%)', right: 'calc(94%)', background: '#43BE5F' });
                setTwo({left: 'calc(19%)', right: 'calc(81%)', background: '#f5504e'});
                setThree({left: 'calc(31%)', right: 'calc(69%)', background: '#3f97f6'});
                setFour({left: 'calc(44%)', right: 'calc(56%)', background: '#f8b042'});
                setFive({right: 'calc(44%)', left: 'calc(56%)', background: '#5BB7C5'});
                setSix({right: 'calc(31%)', left: 'calc(69%)', background: '#90979E'});
                setSeven({right: 'calc(19%)', left: 'calc(81%)', background: 'white'});
                setEight({right: 'calc(6%)', left: 'calc(94%)', background: 'black'});
            }
        },
        [],
    );

  return (
      <span>
          <Handle type="target" position="top" isConnectable="true" style={{ background: 'black' }} />
          <mobiscroll.FormGroup className="node-group-main">
              <mobiscroll.FormGroupTitle className="node-group-text">
                  <div className="mbsc-row mbsc-justify-content-between">
                      <div className="mbsc-col-auto node-button-text">{props.data.title}</div>
                      <div className="mbsc-col-auto"><mobiscroll.Button onClick={(ev) => { OpenNode(ev) }} icon="line-settings" className="node-button-edit" flat={true} color="warning" /></div>
                  </div>
              </mobiscroll.FormGroupTitle>
              <div className="mbsc-btn-group-justified node-group">
                  {(props.data.type === 'multiple' || props.data.type === 'one' || props.data.type === 'two' || props.data.type === 'three' || props.data.type === 'four' || props.data.type === 'five' || props.data.type === 'six' || props.data.type === 'seven' || props.data.type === 'eight') &&
                      <mobiscroll.Button disabled={props.data.type === 'multiple'} onClick={(ev) => { OpenEdge('a') }} className="node-button-out" outline={true} color="success">A</mobiscroll.Button>
                  }
                  {(props.data.type === 'multiple' || props.data.type === 'two' || props.data.type === 'three' || props.data.type === 'four' || props.data.type === 'five' || props.data.type === 'six' || props.data.type === 'seven' || props.data.type === 'eight') &&
                      <mobiscroll.Button disabled={props.data.type === 'multiple'} onClick={(ev) => { OpenEdge('b') }} className="node-button-out" outline={true} color="danger">B</mobiscroll.Button>
                  }
                  {(props.data.type === 'three' || props.data.type === 'four' || props.data.type === 'five' || props.data.type === 'six' || props.data.type === 'seven' || props.data.type === 'eight') &&
                      <mobiscroll.Button onClick={(ev) => { OpenEdge('c') }} className="node-button-out" outline={true} color="primary">C</mobiscroll.Button>
                  }
                  {(props.data.type === 'four' || props.data.type === 'five' || props.data.type === 'six' || props.data.type === 'seven' || props.data.type === 'eight') &&
                      <mobiscroll.Button onClick={(ev) => { OpenEdge('d') }} className="node-button-out" outline={true} color="warning">D</mobiscroll.Button>
                  }
                  {(props.data.type === 'five' || props.data.type === 'six' || props.data.type === 'seven' || props.data.type === 'eight') &&
                      <mobiscroll.Button onClick={(ev) => { OpenEdge('e') }} className="node-button-out" outline={true} color="info">E</mobiscroll.Button>
                  }
                  {(props.data.type === 'six' || props.data.type === 'seven' || props.data.type === 'eight') &&
                      <mobiscroll.Button onClick={(ev) => { OpenEdge('f') }} className="node-button-out" outline={true} color="secondary">F</mobiscroll.Button>
                  }
                  {(props.data.type === 'seven' || props.data.type === 'eight') &&
                      <mobiscroll.Button onClick={(ev) => { OpenEdge('g') }} className="node-button-out" outline={true} color="light">G</mobiscroll.Button>
                  }
                  {props.data.type === 'eight' &&
                      <mobiscroll.Button onClick={(ev) => { OpenEdge('h') }} className="node-button-out" outline={true} color="dark">H</mobiscroll.Button>
                  }
              </div>
          </mobiscroll.FormGroup>
          {(props.data.type === 'multiple' || props.data.type === 'one' || props.data.type === 'two' || props.data.type === 'three' || props.data.type === 'four' || props.data.type === 'five' || props.data.type === 'six' || props.data.type === 'seven' || props.data.type === 'eight') &&
              <Handle type="source" position="bottom" id="a" style={one} isConnectable="true" />
          }
          {(props.data.type === 'multiple' || props.data.type === 'two' || props.data.type === 'three' || props.data.type === 'four' || props.data.type === 'five' || props.data.type === 'six' || props.data.type === 'seven' || props.data.type === 'eight') &&
              <Handle type="source" position="bottom" id="b" style={two} isConnectable="true" />
          }
          {(props.data.type === 'three' || props.data.type === 'four' || props.data.type === 'five' || props.data.type === 'six' || props.data.type === 'seven' || props.data.type === 'eight') &&
              <Handle type="source" position="bottom" id="c" style={three} isConnectable="true" />
          }
          {(props.data.type === 'four' || props.data.type === 'five' || props.data.type === 'six' || props.data.type === 'seven' || props.data.type === 'eight') &&
              <Handle type="source" position="bottom" id="d" style={four} isConnectable="true" />
          }
          {(props.data.type === 'five' || props.data.type === 'six' || props.data.type === 'seven' || props.data.type === 'eight') &&
              <Handle type="source" position="bottom" id="e" style={five} isConnectable="true" />
          }
          {(props.data.type === 'six' || props.data.type === 'seven' || props.data.type === 'eight') &&
              <Handle type="source" position="bottom" id="f" style={six} isConnectable="true" />
          }
          {(props.data.type === 'seven' || props.data.type === 'eight') &&
              <Handle type="source" position="bottom" id="g" style={seven} isConnectable="true" />
          }
          {props.data.type === 'eight' &&
              <Handle type="source" position="bottom" id="h" style={eight} isConnectable="true" />
          }
      </span>
  );
}

export default NodeOutput;
