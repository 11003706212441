import React, { useState , useEffect, useCallback, useRef } from 'react';
import './App.css';
import Login from './Login';
import Header from './Header';
import Workflows from './Workflows';
import Clusters from './Clusters';
import Customers from './Customers';
import Dashboard from './Dashboard';
import Reporting from './Reporting';
import Support from './Support';
import Users from './Users';
import Locations from './Locations';
import Queue from './Queue';
import useWebSocket from "react-use-websocket";
import mobiscroll from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
mobiscroll.settings = {
  theme: 'ios',
  themeVariant: 'light'
}

function App() {
  function Token() {
    let data = window.localStorage.token;

    if (!data) {
      data = '';
    }
    return data;
  }
  function Session() {
    let data = {
      id : Math.floor(Math.random() * 1000000000000),
      product : navigator.product,
      name : navigator.appCodeName,
      version : navigator.appVersion,
      agent : navigator.userAgent,
      platform : navigator.platform,
      language : navigator.language,
      referrer : document.referrer,
      smartdoc : "1.0"
    }
    return data;
  }
  function Empty(props) {
    return Object.keys(props).length === 0;
  }
  function Decode(props) {
    return  atob(props);
  }

  const wsstate = useRef('close');
  const token = useRef(Token());
  const webToken = useRef('');
  const session = useRef(Session());
  const mapquest = useRef('');
  const url = useRef(Decode('d3NzOi8vY29ubmVjdC5zbWFydGRvY21kLmNvbS9sb2dpbg=='));
  const messages = useRef([]);
  const [width, setWidth] = useState(0);
  const [user, setUser] = useState({});
  const [avatar, setAvatar] = useState('');
  const [account, setAccount] = useState({});
  const [location, setLocation] = useState({});
  const [reset, setReset] = useState('');
  const [response, setResponse] = useState({});
  const [navigationMenu, setNavigationMenu] = useState('dashboard');
  const [mobiCountries, setMobiCountries] = useState([]);
  const [mobiStates, setMobiStates] = useState([]);
  const [mobiAccounts, setMobiAccounts] = useState([]);
  const [mobiLocations, setMobiLocations] = useState([]);

  useEffect(() => {
        updateWidth();

        window.addEventListener('resize', updateWidth);

        return () =>
            window.removeEventListener('resize', updateWidth);
      },
      [],
  );

  const updateWidth = () => {
    const width = window.innerWidth;
    setWidth(width);
  }

  const {sendJsonMessage, lastJsonMessage} = useWebSocket(url.current, {
    onOpen: () => wsstate.current = 'open',
    onClose: () => wsstate.current = 'close',
    onError: () => wsstate.current = 'error',
    onMessage: (event) => WSUpdate(event.data),
    retryOnError: true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    share: true
  });

  const WSSend = useCallback(
      (props) => {
        const data = props;
        function Send(props) {
          if ( wsstate.current !== 'open' ) {
            setTimeout(
                () => Send(props),
                500
            );
          } else {
            let message = {
              token: token.current,
              endpoint: 'admin',
              request_id: session.current.id +''+ Date.now(),
              session: session.current,
              task: data.task,
              data: data.data
            };

            messages.current.push(message.request_id);

            sendJsonMessage(message);
          }
        }
        Send(data);
      },
      [],
  );

  function WSReceive(props) {
    const response = props;

    if (response.data && response.data.expired) {
      setUser({});
      window.localStorage.removeItem('token');
      token.current = Token();
    } else if (response.data && response.task) {
      setResponse(response);

      if (response.data && response.data.error) {

        mobiscroll.toast({ message: response.data.error, color: 'warning'});
      }
      if (response.data && response.data.toast) {

        mobiscroll.toast({ message: response.data.toast, color: 'primary'});
      }
    }
  }

  function User() {
    let message = {
      task: 'user'
    };

    WSSend(message);
  }

  function Data() {
    let message = {
      task: 'data'
    };

    WSSend(message);
  }

  function WSUpdate(props) {
    let message = JSON.parse(props);

    if (messages.current.length !== 0) {
      let data = messages.current.filter(function (data) {
        return data === message.request_id;
      });
      if (data.length !== 0) {
        messages.current = messages.current.filter(function (data) {
          return data !== message.request_id;
        });
        WSReceive(message);
      }
    }
  }

  function Logout() {
    url.current = Decode('d3NzOi8vY29ubmVjdC5zbWFydGRvY21kLmNvbS9sb2dpbg==');
    window.localStorage.removeItem('token');
    token.current = Token();
    setUser({});

    mobiscroll.toast({ message: 'You have been logged out', color: 'warning'});
  }

  const PReset = useCallback(() => {
    webToken.current = '';
    setReset('');
  }, []);

  useEffect(() => {
        updateWidth();

        window.addEventListener('resize', updateWidth);

        return () =>
            window.removeEventListener('resize', updateWidth);
      },
      [],
  );

  useEffect(() => {
        if (!Empty(response) && (response.task === 'token' || response.task === 'login' || response.task === 'register' || response.task === 'resetPassword')) {
          if (response.data.url) {
            url.current = Decode(response.data.url);
          }
          if (response.data.mapquest) {
            mapquest.current = response.data.mapquest;
          }
          if (response.data.token) {
            window.localStorage.token = response.data.token;
            token.current = Token();
          }
          if (reset) {
            setReset('');
          }
        }
        if (!Empty(response) && response.task === 'webToken') {
          if (response.data.email) {
            setReset(response.data.email);
          }
        }
        if (!Empty(response) && response.task === 'data') {
          if (response.data.user) {
            setUser(response.data.user);
            let image = "https://www.gravatar.com/avatar/" + response.data.user.avatar + "?d=retro";
            setAvatar(image);
          }
          if (response.data.account) {
            setAccount(response.data.account);
          }
          if (response.data.location) {
            setLocation(response.data.location);
          }
          if (response.data.mobiCountries) {
            setMobiCountries(response.data.mobiCountries);
          }
          if (response.data.mobiStates) {
            setMobiStates(response.data.mobiStates);
          }
          if (response.data.mobiLocations) {
            setMobiLocations(response.data.mobiLocations);
          }
          if (response.data.mobiAccounts) {
            setMobiAccounts(response.data.mobiAccounts);
          }
        }
        if (!Empty(response) && response.task === 'user') {
          if (response.data.user) {
            setUser(response.data.user);
          }
        }
        if (!Empty(response) && response.task === 'saveUser') {
          User();
        }
      },
      [response],
  );

  useEffect(() => {
        if (response.task === 'token' || response.task === 'login' || response.task === 'resetPassword') {
          Data()
        }
      },
      [url.current],
  );

  useEffect(() => {
        if (wsstate.current === 'open') {

          mobiscroll.toast({ message: 'Connected', color: 'primary'});
        } else if (wsstate.current === 'close') {

          mobiscroll.toast({ message: 'Connection closed. Trying to connect', color: 'primary'});
        } else if (wsstate.current === 'error') {

          mobiscroll.toast({ message: 'There was an error. Trying to reconnect', color: 'warning'});
        }
      },
      [wsstate.current],
  );

  useEffect(() => {
        let location = window.location.href;
        const url = new URL(location);
        webToken.current = url.searchParams.get('webtoken');
        window.history.replaceState(null, "SmartDocMD Admin", "/");
        document.title = 'SmartDocMD';

        if (Empty(user) && token.current) {
          let message = {
            task: 'token'
          };
          WSSend(message);
        } else if (Empty(user) && !token.current && webToken.current) {
          let message = {
            task: 'webToken',
            data: {webToken: webToken.current}
          };
          WSSend(message);

          mobiscroll.toast({ message: 'Checking link, please stand by', color: 'primary'});
        }
      },
      [],
  );

  return (
      <mobiscroll.Page className="main-background" theme="ios"  themeVariant="light">
        {Empty(user) && wsstate.current === 'open' &&
            <Login onSubmit={WSSend} reset={reset} onPReset={PReset} webToken={webToken.current}/>
        }
        {!Empty(user) && wsstate.current === 'open' &&
            <Header response={response} width={width} navigationMenu={navigationMenu} onSetNavigationMenu={setNavigationMenu} user={user} onSetUser={setUser} account={account} mobiLocations={mobiLocations} onSetMobiLocations={setMobiLocations} mobiAccounts={mobiAccounts} onSetAccount={setAccount} onSetLocation={setLocation} avatar={avatar} onSubmit={WSSend} onLogout={Logout} mobiStates={mobiStates} />
        }
        {!Empty(user) && wsstate.current === 'open' && navigationMenu === 'dashboard' &&
            <Dashboard onSubmit={WSSend} navigationMenu={navigationMenu} onSetNavigationMenu={setNavigationMenu} response={response} user={user} account={account}/>
        }
        {!Empty(user) && wsstate.current === 'open' && navigationMenu === 'workflows' &&
            <Workflows onSubmit={WSSend} response={response} account={account.id} onSetNavigationMenu={setNavigationMenu} />
        }
        {!Empty(user) && wsstate.current === 'open' && navigationMenu === 'clusters' &&
            <Clusters onSubmit={WSSend} response={response} mapquest={mapquest.current}/>
        }
        {!Empty(user) && wsstate.current === 'open' && navigationMenu === 'customers' &&
            <Customers onSubmit={WSSend} response={response} />
        }
        {!Empty(user) && wsstate.current === 'open' && navigationMenu === 'reporting' &&
            <Reporting onSubmit={WSSend}/>
        }
        {!Empty(user) && wsstate.current === 'open' && navigationMenu === 'support' &&
            <Support onSubmit={WSSend}/>
        }
        {!Empty(user) && wsstate.current === 'open' && navigationMenu === 'users' &&
            <Users onSubmit={WSSend} response={response} />
        }
        {!Empty(user) && wsstate.current === 'open' && navigationMenu === 'locations' &&
            <Locations onSubmit={WSSend} response={response} user={user} account={account} location={location} onSetNavigationMenu={setNavigationMenu} onSetMobiLocations={setMobiLocations} onSetLocation={setLocation} mobiStates={mobiStates} />
        }
        {!Empty(user) && wsstate.current === 'open' && navigationMenu === 'queue' &&
            <Queue onSubmit={WSSend} response={response} user={user} account={account} location={location} onSetNavigationMenu={setNavigationMenu} mobiStates={mobiStates} />
        }
      </mobiscroll.Page>
  );
}

export default App;
