import React, {useCallback, useEffect, useState} from 'react';
import './Locations.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {Popup} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import DayJS from 'react-dayjs';

function Locations(props) {
    function Empty(data) {
        return Object.keys(data).length === 0;
    }

    const [account, setAccount] = useState(props.account.id);
    const [user, setUser] = useState(props.user);
    const [location, setLocation] = useState(props.location);
    const [mobiStates, setMobiStates] = useState(props.mobiStates);
    const [mobiWorkflows, setMobiWorkflows] = useState([]);
    const [mobiClusters, setMobiClusters] = useState([]);
    const [menu, setMenu] = useState([]);
    const [name, setName] = useState(props.location.name);
    const [address, setAddress] = useState(props.location.address);
    const [city, setCity] = useState(props.location.city);
    const [state, setState] = useState(props.location.state);
    const [zip, setZip] = useState(props.location.zip);
    const [workflow, setWorkflow] = useState(props.location.workflow);
    const [clusters, setClusters] = useState(props.location.clusters);
    const [patientDataAddress, setPatientDataAddress] = useState(props.location.patientData.address);
    const [patientDataCity, setPatientDataCity] = useState(props.location.patientData.city);
    const [patientDataDob, setPatientDataDob] = useState(props.location.patientData.dob);
    const [patientDataEmail, setPatientDataEmail] = useState(props.location.patientData.email);
    const [patientDataFirstName, setPatientDataFirstName] = useState(props.location.patientData.firstName);
    const [patientDataLast4, setPatientDataLast4] = useState(props.location.patientData.last4);
    const [patientDataLastName, setPatientDataLastName] = useState(props.location.patientData.lastName);
    const [patientDataMobile, setPatientDataMobile] = useState(props.location.patientData.mobile);
    const [patientDataState, setPatientDataState] = useState(props.location.patientData.state);
    const [patientDataZip, setPatientDataZip] = useState(props.location.patientData.zip);
    const [subsBiometrics, setSubsBiometrics] = useState(props.location.subs.biometrics);
    const [subsCampaigns, setSubsCampaigns] = useState(props.location.subs.campaigns);
    const [subsMarketplace, setSubsMarketplace] = useState(props.location.subs.marketplace);
    const [subsScheduling, setSubsScheduling] = useState(props.location.subs.scheduling);
    const [subsSupport, setSubsSupport] = useState(props.location.subs.support);
    const [subsTeleHealth, setSubsTeleHealth] = useState(props.location.subs.teleHealth);
    const [subsTriage, setSubsTriage] = useState(props.location.subs.triage);

    function Menu(data) {
        let array = menu;
        array = array.map(function(item){
            item.selected = false;
            return item;
        })
        if (data.index === 0) {
            array[0].selected = true;
        } else if (data.index === 1) {
            array[1].selected = true;
        } else if (data.index === 2) {
            array[2].selected = true;
        } else if (data.index === 3) {
            array[3].selected = true;
        } else if (data.index === 4) {
            array[4].selected = true;
        }
        setMenu(array);
    }

    function MenuItem(props) {
        return <li data-selected={props.item.selected}>{props.item.name}</li>;
    }

    function Save() {
        if (workflow === 'none') {
            setWorkflow('');
        }
        let item = {};
        item.id = location.id;
        item.account = account;
        item.address = address;
        item.city = city;
        item.clusters = clusters;
        item.name = name;
        item.patientData = {
            address: patientDataAddress,
            city: patientDataCity,
            dob: patientDataDob,
            email: patientDataEmail,
            firstName: patientDataFirstName,
            last4: patientDataLast4,
            lastName: patientDataLastName,
            mobile: patientDataMobile,
            state: patientDataState,
            zip: patientDataZip
        }
        item.state = state;
        item.subs = {
            biometrics: subsBiometrics,
            campaigns: subsCampaigns,
            marketplace: subsMarketplace,
            scheduling: subsScheduling,
            support: subsSupport,
            teleHealth: subsTeleHealth,
            triage: subsTriage
        }
        item.workflow = workflow;
        item.zip = zip

        let message = {
            task: 'saveLocation',
            data: {
                account: account,
                location: item
            }
        };

        props.onSubmit(message);
    }

    function Delete() {
        mobiscroll.confirm({
            title: 'Delete',
            message: 'Are you sure you want to delete this location? This is permanent',
            okText: 'Delete',            cancelText: 'Cancel',
            callback: (res) => {
                if (res) {

                    let message = {
                        task: 'deleteLocation',
                        data: {
                            account: account,
                            id: location.id
                        }
                    };
                    props.onSubmit(message);
                }
            }
        });
    }

    function Workflows() {
        let message = {
            task: 'locationWorkflows',
            data: {account: props.account}
        };

        props.onSubmit(message);
    }

    useEffect(() => {
            if (!Empty(props.response) && props.response.task === 'locationWorkflows') {
                if (props.response.data.mobiWorkflows) {
                    setMobiWorkflows(props.response.data.mobiWorkflows);
                }
                if (props.response.data.mobiClusters) {
                    setMobiClusters(props.response.data.mobiClusters);
                }
            }
            if (!Empty(props.response) && props.response.task === 'saveLocation') {
                if (props.response.data.location) {
                    props.onSetLocation(props.response.data.location);
                }
            }
            if (!Empty(props.response) && props.response.task === 'deleteLocation') {
                if (props.response.data.mobiLocations) {
                    props.onSetMobiLocations(props.response.data.mobiLocations);
                }
                props.onSetNavigationMenu('dashboard')
            }
        },
        [props.response],
    );

    useEffect(() => {
            let array = [
                { id: 0, name: 'Location', selected: true },
                { id: 1, name: 'Patient Data', selected: false },
                { id: 2, name: 'Subscriptions', selected: false },
                { id: 3, name: 'Configuration', selected: false }
            ]
            setMenu(array);
            Workflows();
        },
        [],
    );

  return (
      <div className="mbsc-grid authenticated">
          <div className="mbsc-row mbsc-padding subtitle">
              <p className="mbsc-txt-l">{location.name} location</p>
          </div>
          <div className="mbsc-row mbsc-padding">
              <div className="mbsc-col-12 mbsc-col-md-2 mbsc-col-sm-3">
                  <mobiscroll.Listview select="single" itemType={MenuItem} data={menu} onItemTap={Menu} />
              </div>
              <div className="mbsc-col-12 mbsc-col-md-10 mbsc-col-sm-9 md-col-border">
                  {menu.length !== 0 && menu[0].selected === true &&
                      <mobiscroll.Card className="user-card">
                          <mobiscroll.CardContent>
                              <div className="mbsc-row mbsc-padding user-card-button mbsc-justify-content-end">
                                  <mobiscroll.Button icon="disk" color="primary" onClick={(ev) => { Save() }} outline={true}>Save changes</mobiscroll.Button>
                                  <mobiscroll.Button icon="remove" color="danger" onClick={(ev) => { Delete() }} outline={true}>Delete location</mobiscroll.Button>
                              </div>
                              <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" name="Name" placeholder="name" value={name} onChange={(ev) => { setName(ev.target.value) }}>
                                  Location name
                              </mobiscroll.Input>
                              <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" name="Address" placeholder="address" value={address} onChange={(ev) => { setAddress(ev.target.value) }}>
                                  Address
                              </mobiscroll.Input>
                              <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" name="City" placeholder="city" value={city} onChange={(ev) => { setCity(ev.target.value) }}>
                                  City
                              </mobiscroll.Input>
                              <mobiscroll.Select placeholder="state" select="single" touchUi={false} data={mobiStates} value={state} buttons={[]} onSet={function (event, inst) {setState(inst.getVal())}} >
                                  <mobiscroll.Input inputStyle="box" labelStyle="inline" >State</mobiscroll.Input>
                              </mobiscroll.Select>
                              <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" name="Zip" placeholder="code" value={zip} onChange={(ev) => { setZip(ev.target.value) }}>
                                  Zip code
                              </mobiscroll.Input>
                          </mobiscroll.CardContent>
                      </mobiscroll.Card>
                  }
                  {menu.length !== 0 && menu[1].selected === true &&
                      <mobiscroll.Card className="user-card">
                          <mobiscroll.CardContent>
                              <div className="mbsc-row mbsc-padding user-card-button mbsc-justify-content-end">
                                  <mobiscroll.Button icon="disk" color="primary" onClick={(ev) => { Save() }} outline={true}>Save changes</mobiscroll.Button>
                              </div>
                              <mobiscroll.Switch inputStyle="box" checked={patientDataFirstName} onChange={(ev) => setPatientDataFirstName(ev.target.checked)}>
                                  First name
                              </mobiscroll.Switch>
                              <mobiscroll.Switch inputStyle="box" checked={patientDataLastName} onChange={(ev) => setPatientDataLastName(ev.target.checked)}>
                                  Last name
                              </mobiscroll.Switch>
                              <mobiscroll.Switch inputStyle="box" checked={patientDataDob} onChange={(ev) => setPatientDataDob(ev.target.checked)}>
                                  Date of birth
                              </mobiscroll.Switch>
                              <mobiscroll.Switch inputStyle="box" checked={patientDataLast4} onChange={(ev) => setPatientDataLast4(ev.target.checked)}>
                                  Last 4 digits SSN
                              </mobiscroll.Switch>
                              <mobiscroll.Switch inputStyle="box" checked={patientDataEmail} onChange={(ev) => setPatientDataEmail(ev.target.checked)}>
                                  Email
                              </mobiscroll.Switch>
                              <mobiscroll.Switch inputStyle="box" checked={patientDataMobile} onChange={(ev) => setPatientDataMobile(ev.target.checked)}>
                                  Mobile
                              </mobiscroll.Switch>
                              <mobiscroll.Switch inputStyle="box" checked={patientDataAddress} onChange={(ev) => setPatientDataAddress(ev.target.checked)}>
                                  Address
                              </mobiscroll.Switch>
                              <mobiscroll.Switch inputStyle="box" checked={patientDataCity} onChange={(ev) => setPatientDataCity(ev.target.checked)}>
                                  City
                              </mobiscroll.Switch>
                              <mobiscroll.Switch inputStyle="box" checked={patientDataState} onChange={(ev) => setPatientDataState(ev.target.checked)}>
                                  State
                              </mobiscroll.Switch>
                              <mobiscroll.Switch inputStyle="box" checked={patientDataZip} onChange={(ev) => setPatientDataZip(ev.target.checked)}>
                                  Zip code
                              </mobiscroll.Switch>
                          </mobiscroll.CardContent>
                      </mobiscroll.Card>
                  }
                  {menu.length !== 0 && menu[2].selected === true &&
                      <mobiscroll.Card className="user-card">
                          <mobiscroll.CardContent>
                              <div className="mbsc-row mbsc-padding user-card-button mbsc-justify-content-end">
                                  <mobiscroll.Button icon="disk" color="primary" onClick={(ev) => { Save() }} outline={true}>Save changes</mobiscroll.Button>
                              </div>
                              <mobiscroll.Switch disable={props.account.subs.triage} inputStyle="box" checked={subsTriage} onChange={(ev) => setSubsTriage(ev.target.checked)}>
                                  Triage
                              </mobiscroll.Switch>
                              <mobiscroll.Switch disable={props.account.subs.teleHealth} inputStyle="box" checked={subsTeleHealth} onChange={(ev) => setSubsTeleHealth(ev.target.checked)}>
                                  TeleHealth
                              </mobiscroll.Switch>
                              <mobiscroll.Switch disable={props.account.subs.support} inputStyle="box" checked={subsSupport} onChange={(ev) => setSubsSupport(ev.target.checked)}>
                                  Support
                              </mobiscroll.Switch>
                              <mobiscroll.Switch disable={props.account.subs.scheduling} inputStyle="box" checked={subsScheduling} onChange={(ev) => setSubsScheduling(ev.target.checked)}>
                                  Scheduling
                              </mobiscroll.Switch>
                              <mobiscroll.Switch disable={props.account.subs.marketplace} inputStyle="box" checked={subsMarketplace} onChange={(ev) => setSubsMarketplace(ev.target.checked)}>
                                  Marketplace
                              </mobiscroll.Switch>
                              <mobiscroll.Switch disable={props.account.subs.campaigns} inputStyle="box" checked={subsCampaigns} onChange={(ev) => setSubsCampaigns(ev.target.checked)}>
                                  Campaigns
                              </mobiscroll.Switch>
                              <mobiscroll.Switch disable={props.account.subs.biometrics} inputStyle="box" checked={subsBiometrics} onChange={(ev) => setSubsBiometrics(ev.target.checked)}>
                                  Biometrics
                              </mobiscroll.Switch>
                          </mobiscroll.CardContent>
                      </mobiscroll.Card>
                  }
                  {menu.length !== 0 && menu[3].selected === true &&
                      <mobiscroll.Card className="user-card">
                          <mobiscroll.CardContent>
                              <div className="mbsc-row mbsc-padding user-card-button mbsc-justify-content-end">
                                  <mobiscroll.Button icon="disk" color="primary" onClick={(ev) => { Save() }} outline={true}>Save changes</mobiscroll.Button>
                              </div>
                              <mobiscroll.Select disabled={!user.priv.superAdmin} placeholder="workflow" select="single" touchUi={false} data={mobiWorkflows} value={workflow} buttons={[]} onSet={function (event, inst) {setWorkflow(inst.getVal())}} >
                                  <mobiscroll.Input inputStyle="box" labelStyle="inline" >Workflow</mobiscroll.Input>
                              </mobiscroll.Select>
                              <mobiscroll.Select disabled={!user.priv.superAdmin} select="multiple" placeholder="workflow" touchUi={false} data={mobiClusters} value={clusters} buttons={[]} onSet={function (event, inst) {setClusters(inst.getVal())}} >
                                  <mobiscroll.Input inputStyle="box" labelStyle="inline" >Clusters</mobiscroll.Input>
                              </mobiscroll.Select>
                          </mobiscroll.CardContent>
                      </mobiscroll.Card>
                  }
              </div>
          </div>
      </div>
  );
}

export default Locations;
