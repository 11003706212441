import React, {useCallback, useEffect, useRef, useState} from 'react';
import './Clusters.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import { Map, Markers, Marker } from '@joeattardi/react-mapquest-static-map';
import {Popup, Select} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';

function Clusters(props) {
    function Empty(props) {
        return Object.keys(props).length === 0;
    }

    const clusterType = useRef([{text: 'National',value: 'national'},{text: 'State', value: 'state'},{text: 'County', value: 'county'}, {text: 'City', value: 'city'}, {text: 'Custom', value: 'custom'}]);
    const [clusterPop, setClusterPop] = useState(false);
    const [newPop, setNewPop] = useState(false);
    const [membersPop, setMembersPop] = useState(false);
    const [id, setId] = useState('');
    const [city, setCity] = useState('');
    const [county, setCounty] = useState('');
    const [lng, setLng] = useState('');
    const [lat, setLat] = useState('');
    const [radius, setRadius] = useState('');
    const [name, setName] = useState('');
    const [state, setState] = useState('');
    const [type, setType] = useState('');
    const [members, setMembers] = useState([]);
    const [clusters, setClusters] = useState([]);

    const openCluster = useCallback((data) => {
        setId(data.id);
        setMembers(data.members);

        if (!data.city){
            setCity('');
        } else {
            setCity(data.city);
        }
        if (!data.county){
            setCounty('');
        } else {
            setCounty(data.county);
        }
        if (!data.gps.lng){
            setLng('');
        } else {
            setLng(data.gps.lng);
        }
        if (!data.gps.radius){
            setRadius('');
        } else {
            setRadius(data.gps.radius);
        }
        if (!data.gps.lat){
            setLat('');
        } else {
            setLat(data.gps.lat);
        }
        if (!data.name){
            setName('');
        } else {
            setName(data.name);
        }
        if (!data.state){
            setState('');
        } else {
            setState(data.state);
        }
        if (!data.type){
            setType('');
        } else {
            setType(data.type);
        }

        setClusterPop(true);
    }, []);

    const closeCluster = useCallback(() => {
        setClusterPop(false);
    }, []);

    const openNewCluster = useCallback((data) => {
        ClearCluster();
        setNewPop(true);
    }, []);

    const closeNewCluster = useCallback(() => {
        setNewPop(false);
    }, []);

    const openMembers = useCallback((data) => {
        if (data.members.length === 0) {

            mobiscroll.toast({ message: "This cluster doesn't have any members", color: 'warning'});
        } else {
            setMembers(data.members);

            setMembersPop(true);
        }
    }, []);

    const closeMembers = useCallback(() => {
        setMembersPop(false);
    }, []);

    function GetClusters() {
        let message = {
            task: 'clusters'
        };

        props.onSubmit(message);

        mobiscroll.toast({ message: 'Getting clusters', color: 'primary'});
    }

    function MemberItem(props) {
        return <li>
            <h3>{props.item.accountName}</h3>
            <p>Location: {props.item.name}</p>
            <p>{props.item.address}, {props.item.city}, {props.item.state}</p>
        </li>;
    }

    function ClearCluster() {
        setId('');
        setCity('');
        setCounty('');
        setLng('');
        setRadius('');
        setLat('');
        setName('');
        setState('');
        setType('');
        setMembers([]);
    }

    function SaveCluster() {
        if (!name) {

            mobiscroll.toast({ message: "Please type a name for this cluster", color: 'warning'});
        } else if (type === 'custom' && (!lng || !lat || !radius)){

            mobiscroll.toast({ message: "Please provide latitude, longitude and radius in miles", color: 'warning'});
        } else if (type === 'county' && (!state || !county)){

            mobiscroll.toast({ message: "Please provide county and state", color: 'warning'});
        } else if (type === 'state' && !state){

            mobiscroll.toast({ message: "Please provide a state", color: 'warning'});
        } else if (type === 'city' && (!state || !city)){

            mobiscroll.toast({ message: "Please provide a state and city", color: 'warning'});
        } else {
            let message = {
                task: 'saveCluster',
                data: { id: id, city: city, county: county, lng: lng, radius: radius, lat: lat, name: name, state: state, type: type }
            };
            props.onSubmit(message);

            mobiscroll.toast({ message: "Saving the cluster", color: 'primary'});
        }
    }

    function NewCluster() {
        if (!name) {

            mobiscroll.toast({ message: "Please type a name for this cluster", color: 'warning'});
        } else if (type === 'custom' && (!lng || !lat || !radius)){

            mobiscroll.toast({ message: "Please provide latitude, longitude and radius in miles", color: 'warning'});
        } else if (type === 'county' && (!state || !county)){

            mobiscroll.toast({ message: "Please provide county and state", color: 'warning'});
        } else if (type === 'state' && !state){

            mobiscroll.toast({ message: "Please provide a state", color: 'warning'});
        } else if (type === 'city' && (!state || !city)){

            mobiscroll.toast({ message: "Please provide a state and city", color: 'warning'});
        } else {
            let message = {
                task: 'newCluster',
                data: { id: id, city: city, county: county, lng: lng, radius: radius, lat: lat, name: name, state: state, type: type }
            };
            props.onSubmit(message);

            mobiscroll.toast({ message: "Saving the new cluster", color: 'primary'});
        }
    }

    function DeleteCluster(card) {
        if (members.length !== 0) {

            mobiscroll.toast({ message: "You can't delete a cluster with active members", color: 'warning'});
        } else {
            mobiscroll.confirm({
                title: 'Delete',
                message: 'Are you sure you want to delete this cluster from the system?',
                okText: 'Delete',            cancelText: 'Cancel',
                callback: (res) => {
                    if (res) {
                        let message = {
                            task: 'deleteCluster',
                            data: { id: id }
                        };
                        props.onSubmit(message);

                        mobiscroll.toast({ message: "You can't delete a cluster with active members", color: 'primary'});
                    }
                }
            });
        }
    }

    useEffect(() => {
            if (!Empty(props.response) && props.response.task === 'clusters') {
                if (props.response.data.clusters) {
                    setClusters(props.response.data.clusters);
                }
            }
            if (!Empty(props.response) && ( props.response.task === 'saveCluster' || props.response.task === 'deleteCluster' )) {
                closeCluster();
                ClearCluster();
                GetClusters();
            }
            if (!Empty(props.response) && props.response.task === 'newCluster') {
                closeNewCluster();
                ClearCluster();
                GetClusters();
            }
        },
        [props.response],
    );

    useEffect(() => {
            GetClusters();
        },
        [],
    );

  return (
      <div className="mbsc-grid">
          <div className="mbsc-row mbsc-justify-content-center mbsc-no-padding">
              {clusters.map((card) => {
                  return (
                      <div key={card.id} className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-lg-6 mbsc-col-xl-4">
                          <mobiscroll.Card>
                              <mobiscroll.CardHeader>
                                  <div id="map">
                                      <Map apiKey={props.mapquest} center={card.map.center} zoom={card.map.zoom}>
                                          <Markers>
                                              {card.markers.map((marker) => {
                                                  return (
                                                      <Marker key={marker} location={marker} />
                                                  );
                                              })}
                                          </Markers>
                                      </Map>
                                  </div>
                              </mobiscroll.CardHeader>
                              <mobiscroll.CardContent>
                                  <div className="mbsc-card-title">{card.name}</div>
                              </mobiscroll.CardContent>
                              <mobiscroll.CardFooter>
                                  <button onClick={() => { openCluster(card) }} className="mbsc-btn-flat">Settings</button>
                                  <button onClick={() => { openMembers(card) }} className="mbsc-btn-flat">Members ({card.members.length})</button>
                                  <button disabled className="mbsc-btn-flat">Type: {card.type}</button>
                              </mobiscroll.CardFooter>
                          </mobiscroll.Card>
                      </div>
                  );
              })}
              <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-lg-6 mbsc-col-xl-4">
                  <mobiscroll.Card>
                      <mobiscroll.CardFooter>
                          <button onClick={() => { openNewCluster() }} className="mbsc-btn-flat">Create new cluster</button>
                      </mobiscroll.CardFooter>
                  </mobiscroll.Card>
              </div>
          </div>
          <Popup theme="ios" themeVariant="light" display="center" fullScreen={true} headerText='Cluster settings' buttons={[{text:'Save',handler: function(event){ SaveCluster() }},{text:'Delete',handler: function(event){ DeleteCluster() }},'cancel']} isOpen={clusterPop} onClose={closeCluster}>
              <mobiscroll.Form>
                  <mobiscroll.FormGroup inset>
                      <mobiscroll.Input type="text" name="name" placeholder="Name"  value={name} onChange={(ev) => { setName(ev.target.value) }}>Name</mobiscroll.Input>
                      <Select disabled={members.length !== 0} label="Cluster type" theme="ios" themeVariant="light" touchUi={false} display="anchored" value={type} onChange={(ev) => { setType(ev.value) }} data={clusterType.current}/>
                  </mobiscroll.FormGroup>
                  {type !== 'national' &&
                      <mobiscroll.FormGroup inset>
                          {type === 'city' && <mobiscroll.Input disabled={members.length !== 0} type="text" name="city" placeholder="City" value={city} onChange={(ev) => { setCity(ev.target.value) }}>City</mobiscroll.Input>}
                          {(type === 'county' || type === 'city') && <mobiscroll.Input disabled={members.length !== 0} type="text" name="county" placeholder="County" value={county} onChange={(ev) => { setCounty(ev.target.value) }}>County</mobiscroll.Input>}
                          {(type === 'state' || type === 'county' || type === 'city') && <mobiscroll.Input disabled={members.length !== 0} type="text" name="state" placeholder="State" value={state} onChange={(ev) => { setState(ev.target.value) }}>State</mobiscroll.Input>}
                      </mobiscroll.FormGroup>
                  }
                  {type === 'custom' &&
                      <mobiscroll.FormGroup inset>
                          <mobiscroll.Input disabled={members.length !== 0} type="text" name="lat" placeholder="Latitude"  value={lat} onChange={(ev) => { setLat(ev.target.value) }}>Latitude</mobiscroll.Input>
                          <mobiscroll.Input disabled={members.length !== 0} type="text" name="lng" placeholder="Longitude" value={lng} onChange={(ev) => { setLng(ev.target.value) }}>Longitude</mobiscroll.Input>
                          <mobiscroll.Input disabled={members.length !== 0} type="text" name="radius" placeholder="Radius" value={radius} onChange={(ev) => { setRadius(ev.target.value) }}>Radius</mobiscroll.Input>
                      </mobiscroll.FormGroup>
                  }
                  {members.length !== 0 &&
                      <mobiscroll.FormGroup inset>
                          <p>Certain cluster attributes can't be changed with active members.</p>
                      </mobiscroll.FormGroup>
                  }
              </mobiscroll.Form>
          </Popup>
          <Popup theme="ios" themeVariant="light" display="center" fullScreen={true} headerText='New cluster' buttons={[{text:'Save',handler: function(event){ NewCluster() }},'cancel']} isOpen={newPop} onClose={closeNewCluster}>
              <mobiscroll.Form>
                  <mobiscroll.FormGroup inset>
                      <mobiscroll.Input type="text" name="name" placeholder="Name"  value={name} onChange={(ev) => { setName(ev.target.value) }}>Name</mobiscroll.Input>
                      <Select disabled={members.length !== 0} label="Cluster type" theme="ios" themeVariant="light" touchUi={false} display="anchored" value={type} onChange={(ev) => { setType(ev.value) }} data={clusterType.current}/>
                  </mobiscroll.FormGroup>
                  {type !== 'national' &&
                      <mobiscroll.FormGroup inset>
                          {type === 'city' && <mobiscroll.Input disabled={members.length !== 0} type="text" name="city" placeholder="City" value={city} onChange={(ev) => { setCity(ev.target.value) }}>City</mobiscroll.Input>}
                          {(type === 'county' || type === 'city') && <mobiscroll.Input disabled={members.length !== 0} type="text" name="county" placeholder="County" value={county} onChange={(ev) => { setCounty(ev.target.value) }}>County</mobiscroll.Input>}
                          {(type === 'state' || type === 'county' || type === 'city') && <mobiscroll.Input disabled={members.length !== 0} type="text" name="state" placeholder="State" value={state} onChange={(ev) => { setState(ev.target.value) }}>State</mobiscroll.Input>}
                      </mobiscroll.FormGroup>
                  }
                  {type === 'custom' &&
                      <mobiscroll.FormGroup inset>
                          <mobiscroll.Input disabled={members.length !== 0} type="text" name="lat" placeholder="Latitude"  value={lat} onChange={(ev) => { setLat(ev.target.value) }}>Latitude</mobiscroll.Input>
                          <mobiscroll.Input disabled={members.length !== 0} type="text" name="lng" placeholder="Longitude" value={lng} onChange={(ev) => { setLng(ev.target.value) }}>Longitude</mobiscroll.Input>
                          <mobiscroll.Input disabled={members.length !== 0} type="text" name="radius" placeholder="Radius" value={radius} onChange={(ev) => { setRadius(ev.target.value) }}>Radius</mobiscroll.Input>
                      </mobiscroll.FormGroup>
                  }
              </mobiscroll.Form>
          </Popup>
          <Popup theme="ios" themeVariant="light" display="center" fullScreen={true} headerText="Cluster members" buttons={['cancel']} isOpen={membersPop} onClose={closeMembers}>
              <mobiscroll.Form>
                  <mobiscroll.FormGroup inset>
                      <mobiscroll.Listview
                          itemType={MemberItem}
                          enhance={true}
                          swipe={false}
                          data={members}
                      />
                  </mobiscroll.FormGroup>
              </mobiscroll.Form>
          </Popup>
      </div>
  );
}

export default Clusters;
