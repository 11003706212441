import React, {useCallback, useEffect, useRef, useState} from 'react';
import './Customers.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import MicRecorder from 'mic-recorder-to-mp3';
import DayJS from 'react-dayjs';
import {Popup} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';

function Customers(props) {
    function Empty(data) {
        return Object.keys(data).length === 0;
    }

    const [customers, setCustomers] = useState([]);
    const [mobiCustomers, setMobiCustomers] = useState([]);

    function OpenCustomer(cstmr){

    }

    function OpenNew(cstmr){

    }

    function GetCustomers() {
        let message = {
            task: 'customers'
        };

        props.onSubmit(message);

        mobiscroll.toast({ message: 'Getting customers', color: 'primary'});
    }

    useEffect(() => {
            if (!Empty(props.response) && props.response.task === 'customers') {
                if (props.response.data.customers) {
                    setCustomers(props.response.data.customers);
                }
                if (props.response.data.mobiCustomers) {
                    setMobiCustomers(props.response.data.mobiCustomers);
                }
            }
        },
        [props.response],
    );

    useEffect(() => {
            GetCustomers();
        },
        [],
    );

  return (
      <div>
          <mobiscroll.Select display="center" select='single' onSet={function (event, inst) {OpenCustomer(inst.getVal())}} filter={true} data={mobiCustomers} buttons={[{text:'New user',handler: function(event, inst){ OpenNew(); inst.hide(); }}]}>
              <mobiscroll.Input inputStyle="box">Select user</mobiscroll.Input>
          </mobiscroll.Select>
      </div>
  );
}

export default Customers;
