import React, {useCallback, useEffect, useRef, useState} from 'react';
import './Dashboard.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faTimeline, faHospital, faChartColumn } from '@fortawesome/free-solid-svg-icons'
import DayJS from 'react-dayjs';
import dayjs from 'dayjs';
import {Popup} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';

function Dashboard(props) {
    function Empty(data) {
        return Object.keys(data).length === 0;
    }

    const [user, setUser] = useState(props.user);

    useEffect(() => {

    }, [props.response])

  return (
      <div className="mbsc-grid">
          <div className="mbsc-row mbsc-justify-content-center">
              <div className="mbsc-col-12 mbsc-col-md-4 mbsc-col-lg-2">
                  <mobiscroll.Card theme="ios" themeVariant="light" className="navigation-pointer">
                      <mobiscroll.CardHeader onClick={(ev) => { props.onSetNavigationMenu('queue') }}>
                          <mobiscroll.CardTitle className="card-dashboard"><h1><FontAwesomeIcon className="navigation-queue" icon={faHospital} /></h1></mobiscroll.CardTitle>
                          <mobiscroll.CardTitle className="card-dashboard"><mobiscroll.CardSubtitle><p>Queue</p></mobiscroll.CardSubtitle></mobiscroll.CardTitle>
                      </mobiscroll.CardHeader>
                  </mobiscroll.Card>
              </div>
              <div className="mbsc-col-12 mbsc-col-md-4 mbsc-col-lg-2">
                  <mobiscroll.Card theme="ios" themeVariant="light" className="navigation-pointer">
                      <mobiscroll.CardHeader onClick={(ev) => { props.onSetNavigationMenu('users') }}>
                          <mobiscroll.CardTitle className="card-dashboard"><h1><FontAwesomeIcon className="navigation-directory" icon={faUsers} /></h1></mobiscroll.CardTitle>
                          <mobiscroll.CardTitle className="card-dashboard"><mobiscroll.CardSubtitle><p>Directory</p></mobiscroll.CardSubtitle></mobiscroll.CardTitle>
                      </mobiscroll.CardHeader>
                  </mobiscroll.Card>
              </div>
              <div className="mbsc-col-12 mbsc-col-md-4 mbsc-col-lg-2">
                  <mobiscroll.Card theme="ios" themeVariant="light" className="navigation-pointer">
                      <mobiscroll.CardHeader onClick={(ev) => { props.onSetNavigationMenu('visits') }}>
                          <mobiscroll.CardTitle className="card-dashboard"><h1><FontAwesomeIcon className="navigation-visits" icon={faTimeline} /></h1></mobiscroll.CardTitle>
                          <mobiscroll.CardTitle className="card-dashboard"><mobiscroll.CardSubtitle><p>Visits</p></mobiscroll.CardSubtitle></mobiscroll.CardTitle>
                      </mobiscroll.CardHeader>
                  </mobiscroll.Card>
              </div>
              <div className="mbsc-col-12 mbsc-col-md-4 mbsc-col-lg-2">
                  <mobiscroll.Card theme="ios" themeVariant="light" className="navigation-pointer">
                      <mobiscroll.CardHeader onClick={(ev) => { props.onSetNavigationMenu('reporting') }}>
                          <mobiscroll.CardTitle className="card-dashboard"><h1><FontAwesomeIcon className="navigation-reports" icon={faChartColumn} /></h1></mobiscroll.CardTitle>
                          <mobiscroll.CardTitle className="card-dashboard"><mobiscroll.CardSubtitle><p>Reports</p></mobiscroll.CardSubtitle></mobiscroll.CardTitle>
                      </mobiscroll.CardHeader>
                  </mobiscroll.Card>
              </div>
          </div>
      </div>
  );
}

export default Dashboard;