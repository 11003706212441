import React, {useCallback, useEffect, useRef, useState} from 'react';
import './Workflows.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import MicRecorder from 'mic-recorder-to-mp3';
import DayJS from 'react-dayjs';
import {Popup, Select, Textarea} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import ReactFlow, { useNodesState, useEdgesState, MiniMap, Background, Controls } from 'react-flow-renderer';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import NodeOutput from './NodeOutput';
import NodeStart from './NodeStart';
import NodeEnd from './NodeEnd';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsers} from "@fortawesome/free-solid-svg-icons";

function Workflows(props) {
    function Empty(data) {
        return Object.keys(data).length === 0;
    }

    const [mobiWorkflows, setMobiWorkflows] = useState([]);
    const [mobiWorkflowsInstance, setMobiWorkflowsInstance] = useState({});
    const [workflow, setWorkflow] = useState({});
    const [flowInstance, setFlowInstance] = useState({});
    const [flowEdgeOptions, setFlowEdgeOptions] = useState({});
    const [flowStyle, setFlowStyle] = useState({});
    const [flowLineStyle, setFlowLineStyle] = useState({});
    const [flowNodeTypes, setFlowNodeTypes] = useState({});
    const [flowSnapGrid, setFlowSnapGrid] = useState([]);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [flowText, setFlowText] = useState('');
    const [flowTitle, setFlowTitle] = useState('');
    const [flowPop, setFlowPop] = useState(false);
    const [newFlowPop, setNewFlowPop] = useState(false);
    const [nodeCard, setNodeCard] = useState(false);
    const [newNodeCard, setNewNodeCard] = useState(false);
    const [edgeCard, setEdgeCard] = useState(false);
    const [nodeCardSettings, setNodeCardSettings] = useState('mbsc-col-12 flow-flex');
    const [node, setNode] = useState({});
    const [nodeType, setNodeType] = useState('');
    const [nodeText, setNodeText] = useState('');
    const [nodeTitle, setNodeTitle] = useState('');
    const [nodeTag, setNodeTag] = useState('');
    const [nodeColor, setNodeColor] = useState('');
    const [nodeOutput, setNodeOutput] = useState('any');
    const [nodeOptions, setNodeOptions] = useState([]);
    const [nodeOptionsStages, setNodeOptionsStages] = useState({});
    const [nodeOptionsDelete, setNodeOptionsDelete] = useState(null);
    const [nodeOptionsText, setNodeOptionsText] = useState('');
    const [edgeTitle, setEdgeTitle] = useState('');
    const [edgeId, setEdgeId] = useState('');
    const [edgeText, setEdgeText] = useState('');
    const [save, setSave] = useState(false);
    const [navigation, setNavigation] = useState('settings');

    const closeFlowPop = useCallback(() => {
        setFlowPop(false);
    }, []);

    function NewFlow() {
        setFlowText('');
        setFlowTitle('');
        setNewFlowPop(true);
    }

    function SaveNewFlow() {
        if (!flowTitle) {
            mobiscroll.toast({ message: 'A flow title is required', color: 'warning'});
        } else if (flowTitle.length <= 3) {
            mobiscroll.toast({ message: 'The title must have at least 3 characters', color: 'warning'});
        } else if (flowTitle > 15) {
            mobiscroll.toast({ message: 'The title must not have more than 15 characters', color: 'warning'});
        } else {
            let message = {
                task: 'newWorkflow',
                data: {account: props.account, title: flowTitle, text: flowText}
            }
            props.onSubmit(message);
        }
    }

    const closeNewFlowPop = useCallback(() => {
        setNewFlowPop(false);
    }, []);

    function Workflows() {
        let message = {
            task: 'workflows',
            data: {account: props.account}
        };

        props.onSubmit(message);
    }

    function Workflow(data){
        setWorkflow({});
        setFlowInstance({});
        setNodes([]);
        setEdges([]);
        setFlowEdgeOptions({});
        setFlowLineStyle({});
        setFlowStyle({});
        setFlowNodeTypes({});
        setFlowSnapGrid([]);

        let message = {
            task: 'workflow',
            data: {account: props.account, id: data}
        };

        props.onSubmit(message);
    }

    function Save(data) {
        let flow = flowInstance.toObject();

        let message = {
            task: 'saveWorkflow',
            data: {
                account: props.account,
                id: workflow.id,
                nodes: flow.nodes,
                edges: flow.edges,
                viewport: flow.viewport,
                title: flowTitle,
                text: flowText
            }
        };

        props.onSubmit(message);
    }

    function Delete() {
        mobiscroll.confirm({
            title: 'Delete',
            message: 'Are you sure you want to delete this workflow? This is permanent',
            okText: 'Delete',            cancelText: 'Cancel',
            callback: (res) => {
                if (res) {
                    let message = {
                        task: 'deleteWorkflow',
                        data: {account: props.account, id: workflow.id}
                    };

                    props.onSubmit(message);
                }
            }
        });
    }

    function Publish() {
        console.log(flowInstance.getNodes());
        console.log(flowInstance.getEdges());
    }

    function Edge(data) {
        setEdgeId(data.edge);
        setEdgeText('');

        let message = {
            task: 'node',
            data: {account: props.account, id: data.id, flow: workflow.id }
        };

        props.onSubmit(message);
    }

    const closeEdgePop = useCallback(() => {
        setNode({});
        setEdgeCard(false);
        setNodeCardSettings('mbsc-col-12 flow-flex');
    }, []);

    function SaveEdge() {
        let message = {
            task: 'saveEdge',
            data: { account: props.account, text: edgeText, edge: edgeId, flow: workflow.id, node: node.id, options: node.options }
        };

        props.onSubmit(message);
    }

    function Node(data) {
        setEdgeId('');

        let message = {
            task: 'node',
            data: {account: props.account, id: data, flow: workflow.id }
        };

        props.onSubmit(message);
    }

    const closeNodePop = useCallback(() => {
        setNode({});
        setNodeCard(false);
        setNodeCardSettings('mbsc-col-12 flow-flex');
    }, []);

    function SaveNode() {
        if (nodeTitle.length < 3) {
            mobiscroll.toast({ message: 'The title must have at least three characters', color: 'warning'});
        } else if (nodeTag.length > 0 && nodeTag.length < 3) {
            mobiscroll.toast({ message: 'The queue tag must have at least three characters', color: 'warning'});
        } else if (nodeTitle.length > 15) {
            mobiscroll.toast({ message: 'The title must not have more than 15 characters', color: 'warning'});
        } else if (nodeTag.length > 15) {
            mobiscroll.toast({ message: 'The queue tag must not have more than 15 characters', color: 'warning'});
        } else {
            let array = nodes;
            let data = {};

            array = array.map(function(item){
                if (item.id === node.id) {
                    data = item.data;
                    item.data = {};
                    item.data.title = nodeTitle;
                    item.data.type = nodeType;
                    item.data.onNode = Node;
                    item.data.onEdge = Edge;
                }
                return item;
            });
            setNodes(array);

            let message = {
                task: 'saveNode',
                data: { account: props.account,
                    type: nodeType,
                    title: nodeTitle,
                    text: nodeText,
                    options: nodeOptions,
                    output: nodeOutput,
                    flow: workflow.id,
                    id: node.id,
                    nodes: nodes,
                    tag: nodeTag,
                    color: nodeColor
                }
            };

            props.onSubmit(message);
        }
    }

    function DeleteNode() {
        if (node.type === 'start') {
            mobiscroll.toast({ message: 'You cannot delete this node type', color: 'warning'});
        } else {
            mobiscroll.confirm({
                title: 'Delete',
                message: 'Are you sure you want to delete this node? This is permanent',
                okText: 'Delete',            cancelText: 'Cancel',
                callback: (res) => {
                    if (res) {
                        let items = edges;

                        items = items.filter(function(item){
                            return item.source !== node.id;
                        });

                        items = items.filter(function(item){
                            return item.target !== node.id;
                        });
                        setEdges(items);

                        let array = nodes;

                        array = array.filter(function(item){
                            return item.id !== node.id;
                        });
                        setNodes(array);

                        let message = {
                            task: 'deleteNode',
                            data: { account: props.account, flow: workflow.id, id: node.id, nodes: array, edges: items }
                        };

                        props.onSubmit(message);
                    }
                }
            });
        }
    }

    function SaveNewNode() {
        if (!nodeType) {
            mobiscroll.toast({ message: 'Please select a node type', color: 'warning'});
        } else if (nodeTitle.length <= 3) {
            mobiscroll.toast({ message: 'The title must have at least three characters', color: 'warning'});
        } else if (nodeTitle.length > 15) {
            mobiscroll.toast({ message: 'The title must not have more than 15 characters', color: 'warning'});
        } else {
            let message = {
                task: 'newNode',
                data: { account: props.account, type: nodeType, title: nodeTitle, text: nodeText, flow: workflow.id }
            };

            props.onSubmit(message);
        }
    }

    function NewNode() {
        setNode({});
        setNodeType('');
        setNodeTitle('');
        setNodeText('');
        setNodeCard(false);
        setEdgeCard(false);
        setNodeCardSettings('mbsc-col-6 flow-flex');
        setNewNodeCard(true);
    }

    function OptionsItem(data) {
        return <li>
            {nodeOutput !== 'any' &&
                <input type="checkbox" defaultChecked={data.item.check}/>
            }
            {data.item.text}
        </li>;
    }

    function NewOption() {
        if (nodeOptionsText) {
            let array = nodeOptions;

            array.push({text: nodeOptionsText, check: false});
            setNodeOptions(array);
            setNodeOptionsText('');
        }
    }

    const closeNewNodePop = useCallback(() => {
        setNode({});
        setNodeType('');
        setNodeTitle('');
        setNodeText('');
        setNodeCard(false);
        setEdgeCard(false);
        setNodeCardSettings('mbsc-col-12 flow-flex');
        setNewNodeCard(false);
    }, []);

    function Connect(node) {
        let item = node;
        item.targetHandle = 'a';
        let edges = flowInstance.getEdges();
        let id = Date.now() + '' + Math.floor(Math.random() * 1000);
        id = id.toString();
        let edge = {
            id: id,
            source: item.source,
            sourceHandle: item.sourceHandle,
            target: item.target,
            targetHandle: item.targetHandle
        }

        if (edges.length !== 0) {
            edges = edges.filter(function(data){
                return (data.source === item.source) && (data.sourceHandle === item.sourceHandle);
            });
            if (edges.length === 0) {
                flowInstance.addEdges(edge);
            } else {
                mobiscroll.toast({ message: 'Multiple targets from a single source are not allowed', color: 'warning'});
            }
        } else {
            flowInstance.addEdges(edge);
        }
    }

    function Instance(data) {
        setFlowInstance(data);
        if (workflow.edges.length !== 0) {
            data.addEdges(workflow.edges);
        }
        if (!Empty(workflow.viewport)) {
            data.setViewport(workflow.viewport);
        }
    }

    function Cancel() {
        if ( Empty(workflow) ) {
            props.onSetNavigationMenu('dashboard');
        }
    }

    useEffect(() => {
            if (!Empty(props.response) && props.response.task === 'workflows') {
                if (props.response.data.mobiWorkflows) {
                    setMobiWorkflows(props.response.data.mobiWorkflows);
                    if (!Empty(mobiWorkflowsInstance)) {
                        mobiWorkflowsInstance.show();
                    }
                }
            }
            if (!Empty(props.response) && props.response.task === 'workflow') {
                setFlowEdgeOptions(props.response.data.flowEdgeOptions);
                setFlowLineStyle(props.response.data.flowLineStyle);
                setFlowStyle(props.response.data.flowStyle);
                setFlowNodeTypes({ nodeOutput: NodeOutput, nodeStart: NodeStart, nodeEnd: NodeEnd });
                setFlowSnapGrid(props.response.data.flowSnapGrid);
                setWorkflow(props.response.data.workflow);
                setFlowTitle(props.response.data.workflow.title);
                setFlowText(props.response.data.workflow.text);
            }
            if (!Empty(props.response) && props.response.task === 'node') {
                if (newNodeCard === true) {
                    closeNewNodePop();
                }
                setNavigation('text');
                setNodeOptionsStages({
                    right: [{
                        percent: -25,
                        color: 'red',
                        text: 'Delete',
                        confirm: true,
                        action: (event) => {
                            setNodeOptionsDelete(event.index);
                        }
                    }]
                });

                setNode(props.response.data.node);
                if (!edgeId) {
                    setNodeTitle(props.response.data.node.title);
                    if (props.response.data.node.text) {
                        setNodeText(props.response.data.node.text);
                    } else {
                        setNodeText('');
                    }
                    if (props.response.data.node.tag) {
                        setNodeTag(props.response.data.node.tag);
                    } else {
                        setNodeTag('');
                    }
                    if (props.response.data.node.color) {
                        setNodeColor(props.response.data.node.color);
                    } else {
                        setNodeColor('');
                    }
                    setNodeType(props.response.data.node.type);
                    setNodeOptions(props.response.data.node.options);
                    setNodeOutput(props.response.data.node.output);
                    setEdgeCard(false);
                    setNodeCard(true);
                } else {
                    let text = props.response.data.node.title + " - Option " + edgeId.toUpperCase();
                    setEdgeTitle(props.response.data.node.title);
                    let array = props.response.data.node.options;
                    array.map(function(item){
                        if (item.id === edgeId) {
                            setEdgeText(item.text);
                        }
                        return item;
                    });
                    setNodeCard(false);
                    setEdgeCard(true);
                }
                setNodeCardSettings('mbsc-col-6 flow-flex');
            }
            if (!Empty(props.response) && props.response.task === 'newNode') {
                if (props.response.data.id){
                    let id = props.response.data.id;
                    let type = props.response.data.type;
                    let title = props.response.data.title;
                    let nodeType = '';
                    if (props.response.data.type === 'end') {
                        nodeType = 'nodeEnd';
                    } else {
                        nodeType = 'nodeOutput';
                    }

                    let item = {
                        id: id,
                        selectable: false,
                        type: nodeType,
                        position: {
                            x: Math.random() * 300,
                            y: Math.random() * 300,
                        },
                        data: {
                            title: title,
                            type: type,
                            onNode: Node,
                            onEdge: Edge
                        }
                    }
                    flowInstance.addNodes(item);
                    setSave(true);
                    closeNewNodePop();
                }
            }
            if (!Empty(props.response) && props.response.task === 'deleteNode') {
                closeNodePop();
            }
            if (!Empty(props.response) && props.response.task === 'newWorkflow') {
                setWorkflow({});
                setFlowInstance({});
                setNodes([]);
                setEdges([]);
                setFlowEdgeOptions({});
                setFlowLineStyle({});
                setFlowStyle({});
                setFlowNodeTypes({});
                setFlowSnapGrid([]);
                Workflows();
                setNewFlowPop(false);
            }
        },
        [props.response],
    );

    useEffect(() => {
            if (nodeOptionsDelete !== null) {
                let array = nodeOptions;
                let index = Number(nodeOptionsDelete);
                array.splice(index, 1);
                setNodeOptions(array);
                setNodeOptionsDelete(null);
            }
        },
        [nodeOptionsDelete],
    );

    useEffect(() => {
            if (save === true) {
                Save();
                setSave(false);
            }
        },
        [nodes],
    );

    useEffect(() => {
            if (!Empty(flowInstance) && workflow.nodes.length === 0) {
                let array = [
                    {
                        id: '1',
                        selectable: false,
                        type: 'nodeStart',
                        data: { title: 'Welcome', onNode: Node },
                        position: { x: 160, y: 40 }
                    },
                    {
                        id: '2',
                        selectable: false,
                        type: 'nodeEnd',
                        data: { title: 'Result', onNode: Node },
                        position: { x: 160, y: 300 }
                    }
                ]
                flowInstance.addNodes(array);
            } else if (!Empty(flowInstance) && workflow.nodes.length !== 0) {
                let array = workflow.nodes;
                array = array.map(function(item){
                    item.data.onNode = Node;
                    item.data.onEdge = Edge;

                    return item;
                });

                flowInstance.addNodes(array);
            }
        },
        [flowInstance],
    );

    useEffect(() => {
            setWorkflow({});
            setFlowInstance({});
            setNodes([]);
            setEdges([]);
            setFlowEdgeOptions({});
            setFlowLineStyle({});
            setFlowStyle({});
            setFlowNodeTypes({});
            setFlowSnapGrid([]);
            Workflows();
        },
        [],
    );

  return (
      <span>
          <div className="mbsc-grid mbsc-no-padding secondary-toolbar">
              <div className="mbsc-row">
                  <mobiscroll.Select placeholder="Select workflow" display="center" select='single' onInit={function (event, inst) { setMobiWorkflowsInstance(inst); }} onCancel={function (event, inst) { Cancel(); }} onSet={function (event, inst) { Workflow(inst.getVal()) }} filter={true} data={mobiWorkflows} buttons={[{text:'New workflow',handler: function(event, inst){ NewFlow(); inst.hide(); }}]}>
                    <mobiscroll.Input></mobiscroll.Input>
                  </mobiscroll.Select>
                  {!Empty(workflow) &&
                      <div className="mbsc-btn-group secondary-toolbar-button-group">
                          <mobiscroll.Button flat={true} color="primary" onClick={() => { setFlowPop(true) }} className="secondary-toolbar-button" data-icon="line-settings">Settings</mobiscroll.Button>
                          <mobiscroll.Button flat={true} color="success" onClick={() => { NewNode() }} className="secondary-toolbar-button" data-icon="aid">New Node</mobiscroll.Button>
                          <mobiscroll.Button flat={true} color="info" onClick={() => { Save() }} className="secondary-toolbar-button" data-icon="disk">Save</mobiscroll.Button>
                          <mobiscroll.Button flat={true} color="secondary" onClick={() => { Publish() }} className="secondary-toolbar-button" data-icon="cloud-upload">Publish</mobiscroll.Button>
                      </div>}
              </div>
          </div>
          <div className="mbsc-grid mbsc-no-padding">
              <div className="mbsc-row">
                  {!Empty(workflow) &&
                      <div className={nodeCardSettings}>
                          <ReactFlow onInit={(ev) => { Instance(ev); }} nodes={nodes} edges={edges} onNodesChange={onNodesChange} onEdgesChange={onEdgesChange} snapToGrid={true} snapGrid={flowSnapGrid} onConnect={Connect} nodeTypes={flowNodeTypes} defaultEdgeOptions={flowEdgeOptions} fitView style={flowStyle} connectionLineStyle={flowLineStyle} >
                              <MiniMap />
                              <Controls />
                              <Background variant="dots" gap={10} />
                          </ReactFlow>
                      </div>
                  }
                  {!Empty(workflow) && ( nodeCard === true || edgeCard === true || newNodeCard === true ) &&
                      <div className={nodeCardSettings}>
                          {nodeCard === true &&
                              <mobiscroll.Card theme="ios" themeVariant="light" id="card-node">
                                  <mobiscroll.CardHeader>
                                      <mobiscroll.CardSubtitle className="card-node">Node settings</mobiscroll.CardSubtitle>
                                      <mobiscroll.CardTitle className="card-node">{nodeTitle}</mobiscroll.CardTitle>
                                  </mobiscroll.CardHeader>
                                  <mobiscroll.TabNav display="inline" theme="ios" themeVariant="light">
                                      <mobiscroll.NavItem selected={navigation === 'text'} onClick={(ev) => { setNavigation('text') }}>Text</mobiscroll.NavItem>
                                      {nodeType === 'multiple' &&
                                          <mobiscroll.NavItem selected={navigation === 'options'} onClick={(ev) => { setNavigation('options') }}>Options</mobiscroll.NavItem>
                                      }
                                      <mobiscroll.NavItem selected={navigation === 'settings'} onClick={(ev) => { setNavigation('settings') }}>Settings</mobiscroll.NavItem>
                                  </mobiscroll.TabNav>
                                  <mobiscroll.CardContent>
                                      {navigation === 'settings' &&
                                          <mobiscroll.Form>
                                              <mobiscroll.FormGroup>
                                                  <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" id="nodeTitle" name="title" placeholder="Node Title" value={nodeTitle} onChange={(ev) => { setNodeTitle(ev.target.value) }}>Title</mobiscroll.Input>
                                                  {nodeType === 'end' &&
                                                      <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" id="nodeTag" name="tag" placeholder="tag" value={nodeTag} onChange={(ev) => { setNodeTag(ev.target.value) }}>Queue tag</mobiscroll.Input>
                                                  }
                                                  {nodeType === 'end' &&
                                                      <mobiscroll.Color display="center" touchUi={false} value={nodeColor} placeholder="color" onSet={(event, inst) => { setNodeColor(event.value); }}>
                                                          <mobiscroll.Input inputStyle="box" labelStyle="inline">Queue tag color</mobiscroll.Input>
                                                      </mobiscroll.Color>
                                                  }
                                                  <mobiscroll.Dropdown disabled inputStyle="box" labelStyle="inline" label="Node type" value={nodeType} onChange={function (event, inst) {setNodeType(event.target.value)}}>
                                                      <option disabled value="start">Welcome</option>
                                                      <option disabled value="end">Result</option>
                                                      <option disabled value="multiple">Multiple choice</option>
                                                      <option value="one">One</option>
                                                      <option value="two">Two</option>
                                                      <option value="three">Three</option>
                                                      <option value="four">Four</option>
                                                      <option value="five">Five</option>
                                                      <option value="six">Six</option>
                                                      <option value="seven">Seven</option>
                                                      <option value="eight">Eight</option>
                                                  </mobiscroll.Dropdown>
                                                  {nodeType === 'multiple' &&
                                                      <mobiscroll.Dropdown inputStyle="box" labelStyle="inline" label="Output" value={nodeOutput} onChange={function (event, inst) {setNodeOutput(event.target.value)}}>
                                                          <option value="any">Any option is selected</option>
                                                          <option value="specific">Specific options</option>
                                                          <option value="number">Number of options</option>
                                                      </mobiscroll.Dropdown>
                                                  }
                                              </mobiscroll.FormGroup>
                                              <mobiscroll.FormGroup inset>
                                                  {!Empty(node) && !Empty(node.author) && <mobiscroll.CardSubtitle>Author: {node.author.firstName} {node.author.lastName} | {node.author.email} @ <DayJS format="dddd, MMMM D, YYYY h:mm A">{node.author.timestamp}</DayJS></mobiscroll.CardSubtitle>}
                                                  {!Empty(node) && !Empty(node.lastChange) && <mobiscroll.CardSubtitle>Last edit: {node.lastChange.firstName} {node.lastChange.lastName} | {node.lastChange.email} @ <DayJS format="dddd, MMMM D, YYYY h:mm A">{node.lastChange.timestamp}</DayJS></mobiscroll.CardSubtitle>}
                                              </mobiscroll.FormGroup>
                                          </mobiscroll.Form>
                                      }
                                      {navigation === 'text' &&
                                          <mobiscroll.Form>
                                              <mobiscroll.FormGroup inset>
                                                  <CKEditor id="editor-focus" editor={ClassicEditor} data={nodeText} onChange={ ( event, editor ) => { setNodeText(editor.getData()) } } />
                                              </mobiscroll.FormGroup>
                                          </mobiscroll.Form>
                                      }
                                      {navigation === 'options' &&
                                          <mobiscroll.Form>
                                              <mobiscroll.FormGroup>
                                                  <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" name="option" placeholder="New option" value={nodeOptionsText} onChange={(ev) => { setNodeOptionsText(ev.target.value) }}/>
                                              </mobiscroll.FormGroup>
                                              <mobiscroll.Button outline={true} className="options-multiple-button" onClick={(ev) => { NewOption() }} color="success">Add</mobiscroll.Button>
                                              <mobiscroll.FormGroup inset>
                                                  <mobiscroll.Listview itemType={OptionsItem} data={nodeOptions} stages={nodeOptionsStages} sortable={{handle: 'left'}}/>
                                              </mobiscroll.FormGroup>
                                          </mobiscroll.Form>
                                      }
                                  </mobiscroll.CardContent>
                                  <mobiscroll.CardFooter>
                                      <div className="mbsc-grid">
                                          <div className="mbsc-row mbsc-justify-content-end">
                                              <div>
                                                  <mobiscroll.Button icon="disk" color="success" onClick={(ev) => { SaveNode() }} flat={true}>Save</mobiscroll.Button>
                                                  <mobiscroll.Button icon="remove" color="danger" onClick={(ev) => { DeleteNode() }} flat={true}>Delete</mobiscroll.Button>
                                                  <mobiscroll.Button icon="arrow-right2" color="primary" onClick={(ev) => { closeNodePop() }} flat={true}>Close</mobiscroll.Button>
                                              </div>
                                          </div>
                                      </div>
                                  </mobiscroll.CardFooter>
                              </mobiscroll.Card>
                          }
                          {edgeCard === true &&
                              <mobiscroll.Card theme="ios" themeVariant="light" id="card-node">
                                  <mobiscroll.CardHeader>
                                      <mobiscroll.CardSubtitle className="card-node">{edgeTitle}</mobiscroll.CardSubtitle>
                                      <mobiscroll.CardTitle className="card-node">{"Option " + edgeId.toUpperCase()}</mobiscroll.CardTitle>
                                  </mobiscroll.CardHeader>
                                  <mobiscroll.CardContent>
                                      <mobiscroll.Form>
                                          <mobiscroll.FormGroup inset>
                                              <CKEditor id="editor-focus" editor={ClassicEditor} data={edgeText} onChange={ ( event, editor ) => { setEdgeText(editor.getData()) } } />
                                          </mobiscroll.FormGroup>
                                          <mobiscroll.FormGroup inset>
                                              {!Empty(node) && !Empty(node.author) && <mobiscroll.CardSubtitle>Author: {node.author.firstName} {node.author.lastName} | {node.author.email} @ <DayJS format="dddd, MMMM D, YYYY h:mm A">{node.author.timestamp}</DayJS></mobiscroll.CardSubtitle>}
                                              {!Empty(node) && !Empty(node.lastChange) && <mobiscroll.CardSubtitle>Last edit: {node.lastChange.firstName} {node.lastChange.lastName} | {node.lastChange.email} @ <DayJS format="dddd, MMMM D, YYYY h:mm A">{node.lastChange.timestamp}</DayJS></mobiscroll.CardSubtitle>}
                                          </mobiscroll.FormGroup>
                                      </mobiscroll.Form>
                                  </mobiscroll.CardContent>
                                  <mobiscroll.CardFooter>
                                      <div className="mbsc-grid">
                                          <div className="mbsc-row mbsc-justify-content-end">
                                              <div>
                                                  <mobiscroll.Button icon="disk" color="success" onClick={(ev) => { SaveEdge() }} flat={true}>Save</mobiscroll.Button>
                                                  <mobiscroll.Button icon="arrow-right2" color="primary" onClick={(ev) => { closeEdgePop() }} flat={true}>Close</mobiscroll.Button>
                                              </div>
                                          </div>
                                      </div>
                                  </mobiscroll.CardFooter>
                              </mobiscroll.Card>
                          }
                          {newNodeCard === true &&
                              <mobiscroll.Card theme="ios" themeVariant="light" id="card-node">
                                  <mobiscroll.CardHeader>
                                      <mobiscroll.CardTitle className="card-node">New node</mobiscroll.CardTitle>
                                  </mobiscroll.CardHeader>
                                  <mobiscroll.CardContent>
                                      <mobiscroll.Form>
                                          <mobiscroll.FormGroup>
                                              <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" id="nodeTitle" name="title" placeholder="Node Title" value={nodeTitle} onChange={(ev) => { setNodeTitle(ev.target.value) }}>Title</mobiscroll.Input>
                                              <mobiscroll.Dropdown inputStyle="box" labelStyle="inline" label="Node type" value={nodeType} onChange={function (event, inst) {setNodeType(event.target.value)}}>
                                                  <option value="one">One</option>
                                                  <option value="two">Two</option>
                                                  <option value="three">Three</option>
                                                  <option value="four">Four</option>
                                                  <option value="five">Five</option>
                                                  <option value="six">Six</option>
                                                  <option value="seven">Seven</option>
                                                  <option value="eight">Eight</option>
                                                  <option value="multiple">Multiple choice</option>
                                                  <option value="end">Result</option>
                                              </mobiscroll.Dropdown>
                                          </mobiscroll.FormGroup>
                                          <mobiscroll.FormGroup inset>
                                              <CKEditor id="editor-focus" editor={ClassicEditor} data={nodeText} onChange={ ( event, editor ) => { setNodeText(editor.getData()) } } />
                                          </mobiscroll.FormGroup>
                                      </mobiscroll.Form>
                                  </mobiscroll.CardContent>
                                  <mobiscroll.CardFooter>
                                      <div className="mbsc-grid">
                                          <div className="mbsc-row mbsc-justify-content-end">
                                              <div>
                                                  <mobiscroll.Button icon="disk" color="success" onClick={(ev) => { SaveNewNode() }} flat={true}>Create</mobiscroll.Button>
                                                  <mobiscroll.Button icon="arrow-right2" color="primary" onClick={(ev) => { closeNewNodePop() }} flat={true}>Close</mobiscroll.Button>
                                              </div>
                                          </div>
                                      </div>
                                  </mobiscroll.CardFooter>
                              </mobiscroll.Card>
                          }
                      </div>
                  }
              </div>
          </div>
          <Popup theme="ios" themeVariant="light" display="center" fullScreen={true} headerText="Flow Settings" buttons={[{text:'Save',handler: function(event){ Save() }},{text:'Delete',handler: function(event){ Delete() }}, 'close']} isOpen={flowPop} onClose={closeFlowPop}>
                  <mobiscroll.Form>
                      <mobiscroll.FormGroup inset>
                          <mobiscroll.Input type="text" id="flowTitle" name="title" placeholder="Workflow Title" value={flowTitle} onChange={(ev) => { setFlowTitle(ev.target.value) }}>Title</mobiscroll.Input>
                          <mobiscroll.Textarea label="Text" name="description" placeholder="Description" value={flowText} onChange={(ev) => { setFlowText(ev.target.value) }}>
                              Description
                          </mobiscroll.Textarea>
                      </mobiscroll.FormGroup>
                      <mobiscroll.FormGroup inset>
                          {!Empty(node) && !Empty(workflow.author) && <mobiscroll.CardSubtitle>Author: {workflow.author.firstName} {workflow.author.lastName} | {workflow.author.email} @ <DayJS format="dddd, MMMM D, YYYY h:mm A">{workflow.author.timestamp}</DayJS></mobiscroll.CardSubtitle>}
                          {!Empty(node) && !Empty(workflow.lastChange) && <mobiscroll.CardSubtitle>Last edit: {workflow.lastChange.firstName} {workflow.lastChange.lastName} | {workflow.lastChange.email} @ <DayJS format="dddd, MMMM D, YYYY h:mm A">{workflow.lastChange.timestamp}</DayJS></mobiscroll.CardSubtitle>}
                      </mobiscroll.FormGroup>
                  </mobiscroll.Form>
              </Popup>
          <Popup theme="ios" themeVariant="light" display="center" fullScreen={true} headerText="New Flow" buttons={[{text:'Create',handler: function(event){ SaveNewFlow() }}, 'cancel']} isOpen={newFlowPop} onClose={closeNewFlowPop}>
                  <mobiscroll.Form>
                      <mobiscroll.FormGroup inset>
                          <mobiscroll.Input type="text" id="flowTitle" name="title" placeholder="Workflow Title" value={flowTitle} onChange={(ev) => { setFlowTitle(ev.target.value) }}>Title</mobiscroll.Input>
                          <mobiscroll.Textarea label="Text" name="description" placeholder="Description" value={flowText} onChange={(ev) => { setFlowText(ev.target.value) }}>
                              Description
                          </mobiscroll.Textarea>
                      </mobiscroll.FormGroup>
                  </mobiscroll.Form>
              </Popup>
      </span>
  );
}

export default Workflows;
