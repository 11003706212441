import React, {useCallback, useEffect, useState} from 'react';
import './Queue.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {Popup} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import DayJS from 'react-dayjs';

function Queue(props) {
    function Empty(data) {
        return Object.keys(data).length === 0;
    }

    const [account, setAccount] = useState(props.account.id);
    const [user, setUser] = useState(props.user);
    const [location, setLocation] = useState(props.location);
    const [mobiStates, setMobiStates] = useState(props.mobiStates);
    const [queue, setQueue] = useState([]);
    const [mobiClusters, setMobiClusters] = useState([]);
    const [navigation, setNavigation] = useState('information');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [dob, setDob] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [last4, setLast4] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobile, setMobile] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [patient, setPatient] = useState({});

    function Discharge() {

    }

    function NewPatient() {

    }

    function MyPatients() {

    }

    function Sort() {

    }

    function Assign() {

    }

    function Video() {

    }

    function Transfer() {

    }

    function Save() {

    }

    function Schedule() {

    }

    function SetPatient(item) {
        setPatient(item);
        if (item.patient.address) {
            setAddress(item.patient.address);
        }
        if (item.patient.city) {
            setCity(item.patient.city);
        }
        if (item.patient.dob) {
            setDob(item.patient.Dob);
        }
        if (item.patient.email) {
            setEmail(item.patient.email);
        }
        if (item.patient.firstName) {
            setFirstName(item.patient.firstName);
        }
        if (item.patient.last4) {
            setLast4(item.patient.last4);
        }
        if (item.patient.lastName) {
            setLastName(item.patient.lastName);
        }
        if (item.patient.mobile) {
            setMobile(item.patient.mobile);
        }
        if (item.patient.state) {
            setState(item.patient.state);
        }
        if (item.patient.zip) {
            setZip(item.patient.zip);
        }
    }

    function SetData(item) {
        if (item.last4) {
            let one = patient;
            patient.last4 = item.last4;
            setPatient(one);
            
            let array = queue;
            array = array.map(function(data){
                if (data.id === patient.id) {
                    data.last4 = item.last4
                    return data;
                }
            })
            setQueue(array);
            setLast4(item.last4);
            console.log(patient);
        }
    }

    function Queue() {
        let message = {
            task: 'queue',
            data: {
                account: account,
                location: location.id
            }
        };

        props.onSubmit(message);
    }

    useEffect(() => {
            if (!Empty(props.response) && props.response.task === 'queue') {
                if (props.response.data.queue) {
                    setQueue(props.response.data.queue);
                }
            }
        },
        [props.response],
    );

    useEffect(() => {
        if (Empty(location)) {
            mobiscroll.toast({ message: 'Please select a location first', color: 'warning'});
            props.onSetNavigationMenu('dashboard');
        } else {
            Queue();
        }
        },
        [],
    );

  return (
      <>
          <div className="mbsc-grid mbsc-no-padding secondary-queue-toolbar">
              <div className="mbsc-row">
                  <div className="mbsc-btn-group secondary-toolbar-button-group">
                      <mobiscroll.Button flat={true} color="primary" onClick={() => { NewPatient(true) }} className="secondary-toolbar-button" data-icon="plus">New patient</mobiscroll.Button>
                      <mobiscroll.Button flat={true} color="success" onClick={() => { MyPatients() }} className="secondary-toolbar-button" data-icon="drawer">My patients</mobiscroll.Button>
                      <mobiscroll.Button flat={true} color="info" onClick={() => { Sort() }} className="secondary-toolbar-button" data-icon="material-list">Sort</mobiscroll.Button>
                  </div>
              </div>
          </div>
          <div className="mbsc-grid authenticated">
              <div className="mbsc-row mbsc-padding">
                  <div className="mbsc-col-12">
                      {queue.length === 0 &&
                          <mobiscroll.Card>
                              <mobiscroll.CardHeader>
                                  <mobiscroll.CardSubtitle><h4>There are no patients in the queue</h4></mobiscroll.CardSubtitle>
                              </mobiscroll.CardHeader>
                          </mobiscroll.Card>
                      }
                      <mobiscroll.Accordion>
                          {queue.length !== 0 && queue.map((item) => {
                              return (
                                  <mobiscroll.Card collapsible>
                                      <mobiscroll.CardHeader onClick={(ev) => { SetPatient(item) }}>
                                          {item.origin === 'manual' &&
                                              <span className="mbsc-ic mbsc-ic-line-user queue-icon mbsc-avatar"></span>
                                          }
                                          {item.origin === 'text' &&
                                              <span className="mbsc-ic mbsc-ic-line-phone queue-icon mbsc-avatar"></span>
                                          }
                                          {item.origin === 'web' &&
                                              <span className="mbsc-ic mbsc-ic-line-world queue-icon mbsc-avatar"></span>
                                          }
                                          { ( item.patient.firstName || item.patient.lastName ) ?
                                              <mobiscroll.CardTitle>{item.patient.firstName} {item.patient.lastName}</mobiscroll.CardTitle>
                                              :
                                              <mobiscroll.CardTitle>{item.patient.email && <> {item.patient.email} </>} {item.patient.mobile && !item.patient.email && <> {item.patient.mobile} </>}</mobiscroll.CardTitle>
                                          }
                                          <mobiscroll.CardSubtitle>Arrived on <DayJS format="dddd, MMMM D, YYYY h:mm A">{parseInt(item.time.arrival)}</DayJS></mobiscroll.CardSubtitle>
                                      </mobiscroll.CardHeader>
                                      <mobiscroll.CardContent>
                                          <mobiscroll.TabNav display="inline">
                                              <mobiscroll.NavItem selected={navigation === 'information'} onClick={(ev) => { setNavigation('information') }} >Information</mobiscroll.NavItem>
                                              {location.subs.triage &&
                                                  <mobiscroll.NavItem selected={navigation === 'flow'} onClick={(ev) => { setNavigation('flow') }} >Flow</mobiscroll.NavItem>
                                              }
                                              {item.patient.mobile &&
                                                  <mobiscroll.NavItem selected={navigation === 'text'} onClick={(ev) => { setNavigation('text') }} >Text</mobiscroll.NavItem>
                                              }
                                              {item.patient.email &&
                                                  <mobiscroll.NavItem selected={navigation === 'email'} onClick={(ev) => { setNavigation('email') }} >Email</mobiscroll.NavItem>
                                              }
                                              <mobiscroll.NavItem selected={navigation === 'history'} onClick={(ev) => { setNavigation('history') }} >History</mobiscroll.NavItem>
                                          </mobiscroll.TabNav>
                                          {navigation === 'information' &&
                                              <>
                                                  {location.patientData.firstName &&
                                                      <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" name="firstName" placeholder="first name" value={firstName} onChange={(ev) => { setFirstName(ev.target.value) }}>
                                                          First name
                                                      </mobiscroll.Input>
                                                  }
                                                  {location.patientData.lastName &&
                                                      <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" name="lastName" placeholder="last name" value={lastName} onChange={(ev) => { setLastName(ev.target.value) }}>
                                                          Last name
                                                      </mobiscroll.Input>
                                                  }
                                                  {location.patientData.email &&
                                                      <mobiscroll.Input inputStyle="box" labelStyle="inline" type="email" name="email" placeholder="email address" value={email} onChange={(ev) => { setEmail(ev.target.value) }}>
                                                          Email
                                                      </mobiscroll.Input>
                                                  }
                                                  {location.patientData.mobile &&
                                                      <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" name="mobile" placeholder="mobile number" value={mobile} onChange={(ev) => { setMobile(ev.target.value) }}>
                                                          Mobile
                                                      </mobiscroll.Input>
                                                  }
                                                  {location.patientData.dob &&
                                                      <mobiscroll.Date touchUi={false} display="bubble" value={dob} onSet={(event) => { setDob(event.valueText) }}>
                                                          <mobiscroll.Input inputStyle="box" labelStyle="inline" placeholder="date" >Date of birth</mobiscroll.Input>
                                                      </mobiscroll.Date>
                                                  }
                                                  {location.patientData.last4 &&
                                                      <mobiscroll.Numpad display="bubble" allowLeadingZero={true} template="dddd" value={last4} onSet={function (event, inst) {SetData( {"last4": inst.getVal()} )}}>
                                                          <mobiscroll.Input inputStyle="box" labelStyle="inline" placeholder="ssn">Last 4 of SSN</mobiscroll.Input>
                                                      </mobiscroll.Numpad>
                                                  }
                                                  {location.patientData.address &&
                                                      <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" name="address" placeholder="address" value={address} onChange={(ev) => { setAddress(ev.target.value) }}>
                                                          Address
                                                      </mobiscroll.Input>
                                                  }
                                                  {location.patientData.city &&
                                                      <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" name="city" placeholder="city" value={city} onChange={(ev) => { setCity(ev.target.value) }}>
                                                          City
                                                      </mobiscroll.Input>
                                                  }
                                                  {location.patientData.state &&
                                                      <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" name="state" placeholder="state" value={state} onChange={(ev) => { setState(ev.target.value) }}>
                                                          State
                                                      </mobiscroll.Input>
                                                  }
                                                  {location.patientData.zip &&
                                                      <mobiscroll.Input inputStyle="box" labelStyle="inline" type="text" name="zip" placeholder="code" value={zip} onChange={(ev) => { setZip(ev.target.value) }}>
                                                          Zip code
                                                      </mobiscroll.Input>
                                                  }
                                              </>
                                          }
                                          <mobiscroll.CardFooter>
                                              <div className="mbsc-grid">
                                                  <div className="mbsc-row mbsc-justify-content-between">
                                                      <div className="assign-note">
                                                          {item.owner.email &&
                                                              <mobiscroll.Note color="info">Assigned to {item.owner.firstName} {item.owner.lastName} | {item.owner.email}</mobiscroll.Note>
                                                          }
                                                      </div>
                                                      <div>
                                                          <mobiscroll.Button icon="disk" color="secondary" onClick={(ev) => { Save() }} flat={true}>Save</mobiscroll.Button>
                                                          <mobiscroll.Button icon="drawer" color="info" onClick={(ev) => { Assign() }} flat={true}>Assign</mobiscroll.Button>
                                                          <mobiscroll.Button icon="checkmark" color="success" onClick={(ev) => { Discharge() }} flat={true}>Discharge</mobiscroll.Button>
                                                          {location.subs.teleHealth &&
                                                              <mobiscroll.Button icon="play" color="primary" onClick={(ev) => { Video() }} flat={true}>Video</mobiscroll.Button>
                                                          }
                                                          {location.subs.scheduling &&
                                                              <mobiscroll.Button icon="line-calendar" color="warning" onClick={(ev) => { Schedule() }} flat={true}>Schedule</mobiscroll.Button>
                                                          }
                                                          <mobiscroll.Button icon="redo2" color="info" onClick={(ev) => { Transfer() }} flat={true}>Transfer</mobiscroll.Button>
                                                      </div>
                                                  </div>
                                              </div>
                                          </mobiscroll.CardFooter>
                                      </mobiscroll.CardContent>
                                  </mobiscroll.Card>
                              );
                          })}
                      </mobiscroll.Accordion>
                  </div>
              </div>
          </div>
      </>
  );
}

export default Queue;
