import React, { useState } from 'react';
import './Login.css';
import {Popup} from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';

function Login(props) {
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [passReset, setPassReset] = useState('');
    const [passHash, setPassHash] = useState('');

    function Password() {
        if (!email) {
            mobiscroll.toast({ message: 'Please enter your account email', color: 'warning'});
        } else {
            let message = {
                task: 'password',
                data: { path: 'operations', email: email }
            };
            props.onSubmit(message);
        }
    }

    function Reset() {
        if (!passReset || !passHash) {
            mobiscroll.toast({ message: 'Please type a new password', color: 'warning'});
        } else if (passReset !== passHash) {
            mobiscroll.toast({ message: 'Please ensure both passwords match', color: 'warning'});
        } else {
            let message = {
                task: 'resetPassword',
                data: { email: props.reset, pass: passReset, webToken: props.webToken }
            };
            props.onSubmit(message);
        }
    }

    function Submit() {
        let message = {
            task: 'login',
            data: { email: email, pass: pass }
        };
        props.onSubmit(message);
    }

    return (
        <div className="mbsc-grid">
            <div className="mbsc-row mbsc-justify-content-center">
                <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-5">
                    <mobiscroll.Card theme="ios" themeVariant="light" id="card-login">
                        <div className="md-logo micons icon-mbsc-logo"></div>
                        <mobiscroll.CardHeader>
                            <mobiscroll.CardTitle className="card-login">Dashboard</mobiscroll.CardTitle>
                        </mobiscroll.CardHeader>
                        <mobiscroll.CardContent>
                            <mobiscroll.Form theme="ios" themeVariant="light" novalidate>
                                <mobiscroll.FormGroup inset>
                                    <mobiscroll.Input required type="email" name="Email" placeholder="Email" value={email} onChange={(ev) => { setEmail(ev.target.value) }} />
                                    <mobiscroll.Input required minLength="8" type="password" name="Password" placeholder="Password" passwordToggle={true} icon="none" iconAlign="right" value={pass} onChange={(ev) => { setPass(ev.target.value) }} />
                                </mobiscroll.FormGroup>
                                <mobiscroll.FormGroup inset className="mbsc-padding mbsc-align-center">
                                    <a href="#" onClick={Password}>Forgot password?</a>
                                </mobiscroll.FormGroup>
                                <mobiscroll.FormGroup inset className="mbsc-padding">
                                    <mobiscroll.Button onClick={Submit} block={true}>Sign in</mobiscroll.Button>
                                </mobiscroll.FormGroup>
                            </mobiscroll.Form>

                        </mobiscroll.CardContent>
                    </mobiscroll.Card>
                </div>
            </div>
            <Popup theme="ios" themeVariant="light" display="center" fullScreen={true} headerText="Password Reset" buttons={[]} isOpen={props.reset} onClose={props.onPReset}>
                <mobiscroll.Form>
                    <mobiscroll.FormGroup inset>
                        <mobiscroll.Input type="password" name="passReset" passwordToggle={true} icon="none" iconAlign="right" placeholder="New password" value={passReset} onChange={(ev) => { setPassReset(ev.target.value) }}>New password</mobiscroll.Input>
                        <mobiscroll.Input type="password" name="passHash" passwordToggle={true} icon="none" iconAlign="right" placeholder="Confirm password" value={passHash} onChange={(ev) => { setPassHash(ev.target.value) }}>Confirm password</mobiscroll.Input>
                    </mobiscroll.FormGroup>
                    <mobiscroll.FormGroup inset>
                        <mobiscroll.Button onClick={Reset} block={true}>Reset</mobiscroll.Button>
                        <mobiscroll.Button onClick={props.onPReset} block={true}>Cancel</mobiscroll.Button>
                    </mobiscroll.FormGroup>
                </mobiscroll.Form>
            </Popup>
        </div>
    );
}

export default Login;
