import React, {useEffect, useState} from 'react';
import './NodeStart.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {Handle} from 'react-flow-renderer';

function NodeStart(props) {
    const [one, setOne] = useState({});
    const [title, setTitle] = useState(props.data.title);

    function OpenNode() {
        props.data.onNode(props.id);
    }

    useEffect(() => {
            if (props.data.title) {
                setTitle(props.data.title);
            }
        },
        [props.data.title],
    );

    useEffect(() => {
            setOne({ background: '#43BE5F' });
        },
        [],
    );

  return (
      <span>
          <mobiscroll.FormGroup className="node-group-main">
              <mobiscroll.FormGroupTitle className="node-group-start">
                  <div className="mbsc-row mbsc-justify-content-between">
                      <div className="mbsc-col-auto node-button-text">{title}</div>
                      <div className="mbsc-col-auto"><mobiscroll.Button onClick={(ev) => { OpenNode(ev) }} icon="line-settings" className="node-button-startend" flat={true} color="warning" /></div>
                  </div>
              </mobiscroll.FormGroupTitle>
          </mobiscroll.FormGroup>
          <Handle type="source" position="bottom" id="a" style={one} isConnectable="true" />
      </span>
  );
}

export default NodeStart;
