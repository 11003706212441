import React, {useCallback, useEffect, useState} from 'react';
import './Users.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {Popup} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import DayJS from 'react-dayjs';

function Users(props) {
    function Empty(data) {
        return Object.keys(data).length === 0;
    }

    const [newPop, setNewPop] = useState(false);
    const [email, setEmail] = useState('');
    const [avatar, setAvatar] = useState('');
    const [superAdmin, setSuperAdmin] = useState(false);
    const [superSupport, setSuperSupport] = useState(false);
    const [superViewOnly, setSuperViewOnly] = useState(false);
    const [mobile, setMobile] = useState('');
    const [lastLogin, setLastLogin] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [users, setUsers] = useState([]);
    const [mobiUsers, setMobiUsers] = useState([]);
    const [usersSelectInstance, setUsersSelectInstance] = useState('');

    const openNewUser = useCallback((data) => {
        ClearUser();
        setNewPop(true);
    }, []);

    const closeNewUser = useCallback(() => {
        setNewPop(false);
    }, []);

    function NewUser(){
        if (!email) {
            mobiscroll.toast({ message: 'Please enter an email address', color: 'warning'});

        } else {
            let message = {
                task: 'newSystemUser',
                data: { email: email, firstName: firstName, lastName: lastName, mobile: mobile, superAdmin: superAdmin, superSupport: superSupport, superViewOnly: superViewOnly }
            };
            props.onSubmit(message);

            mobiscroll.toast({ message: 'Saving the new user', color: 'primary'});
        }

    }

    function OpenUser(usr){
        const array = users;

        array.map(function(data){
            if (data.email === usr) {
                setEmail(data.email);
                setSuperAdmin(data.priv.superAdmin);
                setSuperSupport(data.priv.superSupport);
                setSuperViewOnly(data.priv.superViewOnly);
                if (!data.avatar) {
                    setAvatar('');
                } else {
                    setAvatar(data.avatar);
                }
                if (!data.mobile) {
                    setMobile('');
                } else {
                    setMobile(data.mobile);
                }
                if (!data.lastName) {
                    setLastName('');
                } else {
                    setLastName(data.lastName);
                }
                if (!data.firstName) {
                    setFirstName('');
                } else {
                    setFirstName(data.firstName);
                }
                if (!data.lastLogin) {
                    setLastLogin('');
                } else {
                    setLastLogin(Number(data.lastLogin));
                }
            }
            return data;
        })
    }

    function SaveUser(){
        if (superViewOnly) {
            setSuperAdmin(false);
        }
        let message = {
            task: 'saveSystemUser',
            data: { email: email, firstName: firstName, lastName: lastName, mobile: mobile, superAdmin: superAdmin, superSupport: superSupport, superViewOnly: superViewOnly }
        };
        props.onSubmit(message);

        mobiscroll.toast({ message: 'Saving the user', color: 'primary'});
    }

    function ClearUser(){
        setEmail('');
        setAvatar('');
        setSuperAdmin(false);
        setSuperSupport(false);
        setSuperViewOnly(false);
        setMobile('');
        setLastName('');
        setFirstName('');
        setLastLogin('');
    }

    function DeleteUser(data){
        mobiscroll.confirm({
            title: 'Remove',
            message: 'Are you sure you want to delete this user from the system?',
            okText: 'Delete',            cancelText: 'Cancel',
            callback: (res) => {
                if (res) {
                    let message = {
                        task: 'deleteSystemUser',
                        data: { email: email }
                    };
                    props.onSubmit(message);

                    mobiscroll.toast({ message: 'Deleting the user', color: 'primary'});
                }
            }
        });
    }

    function GetUsers() {
        let message = {
            task: 'users'
        };

        props.onSubmit(message);

        mobiscroll.toast({ message: 'Getting users', color: 'primary'});
    }

    useEffect(() => {
            if (!Empty(props.response) && props.response.task === 'users') {
                if (props.response.data.users) {
                    setUsers(props.response.data.users);
                }
                if (props.response.data.mobiUsers) {
                    setMobiUsers(props.response.data.mobiUsers);
                }
            }
            if (!Empty(props.response) && props.response.task === 'newSystemUser') {
                closeNewUser();
                ClearUser();
                GetUsers();
            }
            if (!Empty(props.response) && ( props.response.task === 'saveSystemUser' || props.response.task === 'deleteSystemUser' )) {
                ClearUser();
                GetUsers();
                usersSelectInstance.setVal(null);
            }
        },
        [props.response],
    );

    useEffect(() => {
            GetUsers();
        },
        [],
    );

  return (
      <div>
          <mobiscroll.Select display="center" select='single' onSet={function (event, inst) {OpenUser(inst.getVal()); setUsersSelectInstance(inst);}} filter={true} data={mobiUsers} buttons={[{text:'New user',handler: function(event, inst){ openNewUser(); inst.hide(); }}]}>
              <mobiscroll.Input inputStyle="box">Select user</mobiscroll.Input>
          </mobiscroll.Select>
          {email && !newPop &&
              <mobiscroll.Card theme="ios" themeVariant="light" id="card-users">
                  <mobiscroll.CardContent>
                      <mobiscroll.Form>
                          <mobiscroll.FormGroup inset>
                              <mobiscroll.Input disabled type="text" name="email" placeholder="Email"  value={email}>Email</mobiscroll.Input>
                              <mobiscroll.Input type="text" name="firstName" placeholder="First name" value={firstName} onChange={(ev) => { setFirstName(ev.target.value) }}>First Name</mobiscroll.Input>
                              <mobiscroll.Input type="text" name="lastName" placeholder="Last name" value={lastName} onChange={(ev) => { setLastName(ev.target.value) }}>Last Name</mobiscroll.Input>
                              <mobiscroll.Input type="tel" name="mobile" placeholder="Mobile number" value={mobile} onChange={(ev) => { setMobile(ev.target.value) }}>Mobile</mobiscroll.Input>
                          </mobiscroll.FormGroup>
                          <mobiscroll.FormGroup inset>
                              <mobiscroll.Switch checked={superAdmin} onChange={(ev) => { setSuperAdmin(ev.target.checked) }} color="primary">
                                  Administrator
                              </mobiscroll.Switch>
                              <mobiscroll.Switch checked={superSupport} onChange={(ev) => { setSuperSupport(ev.target.checked) }} color="primary">
                                  Support
                              </mobiscroll.Switch>
                              <mobiscroll.Switch checked={superViewOnly} onChange={(ev) => { setSuperViewOnly(ev.target.checked) }} color="primary">
                                  View Only
                              </mobiscroll.Switch>
                          </mobiscroll.FormGroup>
                      </mobiscroll.Form>
                  </mobiscroll.CardContent>
                  <mobiscroll.CardFooter>
                      <button onClick={() => { SaveUser() }} className="mbsc-btn-flat" data-icon="disk">Save</button>
                      <button onClick={() => { DeleteUser() }} className="mbsc-btn-flat" data-icon="remove">Delete</button>
                      {lastLogin && <button disabled className="mbsc-btn-flat">Last login <DayJS format="dddd, MMMM D, YYYY h:mm A">{lastLogin}</DayJS></button>}
                  </mobiscroll.CardFooter>
              </mobiscroll.Card>
          }
          <Popup theme="ios" themeVariant="light" display="center" fullScreen={true} headerText="New User" buttons={[{text:'Save',handler: function(event){ NewUser() }}, 'cancel']} isOpen={newPop} onClose={closeNewUser}>
              <mobiscroll.Form>
                  <mobiscroll.FormGroup inset>
                      <mobiscroll.Input type="email" name="email" placeholder="Email"  value={email} onChange={(ev) => { setEmail(ev.target.value) }}>Email</mobiscroll.Input>
                      <mobiscroll.Input type="text" name="firstName" placeholder="First name" value={firstName} onChange={(ev) => { setFirstName(ev.target.value) }}>First Name</mobiscroll.Input>
                      <mobiscroll.Input type="text" name="lastName" placeholder="Last name" value={lastName} onChange={(ev) => { setLastName(ev.target.value) }}>Last Name</mobiscroll.Input>
                      <mobiscroll.Input type="tel" name="mobile" placeholder="Mobile number" value={mobile} onChange={(ev) => { setMobile(ev.target.value) }}>Mobile</mobiscroll.Input>
                  </mobiscroll.FormGroup>
                  <mobiscroll.FormGroup inset>
                      <mobiscroll.Switch checked={superAdmin} onChange={(ev) => { setSuperAdmin(ev.target.checked) }} color="primary">
                          Administrator
                      </mobiscroll.Switch>
                      <mobiscroll.Switch checked={superSupport} onChange={(ev) => { setSuperSupport(ev.target.checked) }} color="primary">
                          Support
                      </mobiscroll.Switch>
                      <mobiscroll.Switch checked={superViewOnly} onChange={(ev) => { setSuperViewOnly(ev.target.checked) }} color="primary">
                          View Only
                      </mobiscroll.Switch>
                  </mobiscroll.FormGroup>
              </mobiscroll.Form>
          </Popup>
      </div>
  );
}

export default Users;
