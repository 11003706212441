import React, {useEffect} from 'react';
import './NodeEnd.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {Handle} from 'react-flow-renderer';

function NodeEnd(props) {

    function OpenNode() {
        props.data.onNode(props.id);
    }

    useEffect(() => {

        },
        [],
    );

  return (
      <span>
          <Handle type="target" position="top" isConnectable="true" style={{ background: 'black' }} />
          <mobiscroll.FormGroup className="node-group-main">
              <mobiscroll.FormGroupTitle className="node-group-end">
                  <div className="mbsc-row mbsc-justify-content-between">
                      <div className="mbsc-col-auto node-button-text">{props.data.title}</div>
                      <div className="mbsc-col-auto"><mobiscroll.Button onClick={(ev) => { OpenNode(ev) }} icon="line-settings" className="node-button-startend" flat={true} color="warning" /></div>
                  </div>
              </mobiscroll.FormGroupTitle>
          </mobiscroll.FormGroup>
      </span>
  );
}

export default NodeEnd;
